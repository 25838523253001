import React from 'react'
import { Link } from 'react-router-dom';
import s from "./Footer.module.css";


const Footer = () => {
  return (
    <>
      <div className={ s.blankSpace }>
      </div>
      <footer>
        <Link to='/about-us'>About Us</Link>
        <Link to='/accessibility'>Accessibility</Link>
        <Link to='/contact-us'>Contact Us</Link>
        <Link to='/cookies'>Cookies</Link>
        <Link to='/glossary'>Glossary</Link>
        <a href='https://www.essex.gov.uk/topic/privacy-and-data-protection' target='_blank' rel="noreferrer">Privacy</a>
        <a href='https://twitter.com/EssexAir' target='_blank' rel="noreferrer">Twitter</a>
      </footer>
    </>
  )
}

export default Footer
