import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import Footer from '../components/Footer';
import MarkdownContent from '../components/MarkdownContent';
import ListTallItem from '../components/ListTallItem';

import content from '../data/pageContent.json';
import s from './AdditionalResources.module.css';

const AdditionalResources = (props) => {


  const [tabSelection, setTabSelection] = useState('quality');
  

  const page = content['what-can-i-do'];

  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);


  const handleMainTab = (e) => {
    setTabSelection(e);
  }



  const setup = [
    {
      key: '/school-zone/clean-air-for-schools',
      to: 'https://www.transform-our-world.org/programmes/clean-air-for-schools',
      title: 'Transform Our World - Clean Air for Schools',
      subtitle: 'Free practical tools and curriculum-linked educational resources to promote cleaner air at your school. The Clean Air for Schools Framework will also help you create a tailored clean air action plan for your school.',
      image: '/images/Shared/School/temp2.png',
      button: true
    },

    {
      key: '/school-zone/clean-air-hub',
      to: 'https://calculator.cleanairhub.org.uk/',
      title: 'Clean Air Hub - Air Pollution Calculator',
      subtitle: "An online air pollution calculator which allows pupils to discover their home's estimated air pollution footprint and learn what steps they could take to reduce it.",
      image: '/images/Shared/School/temp3.png',
      button: true
    },

    {
      key: '/school-zone/clean-air-for-schools',
      to: 'https://www.actionforcleanair.org.uk/campaigns/clean-air-day/school-resources',
      title: 'Global Action Plan - Clean Air Day Resources for Schools',
      subtitle: "Information, assembly packs and other resources to help your school celebrate Clean Air Day, the UK's largest air pollution campaign. Resources include lesson plans for KS1, KS2 and KS3 which could be used at any time, as well as posters, pledge cards and social media graphics to help you get involved on Clean Air Day itself.",
      image: '/images/Shared/School/temp1.png',
      button: true
    }
  ]




  return (
    <main>
      <section className={ s.section }>
        <h3 className={ 'section-header' }>Additional Resources</h3>
      </section>

      <div className={ 'body' }>
        <div className={ 'body-container' }>
          <div className='markdown-wrapper'>
            <section>
              <MarkdownContent pathname='/schoolZone/additional-resources'/>
            </section>
          </div>


          <section className={ s.section }>
            <ListTallItem
              key={ setup[0].key }
              to={ setup[0].to }
              title={ setup[0].title }
              subtitle={ setup[0].subtitle }
              image={ setup[0].image }
              button={ true }
              buttonText="Learn More"
              external={ true }/>
          </section>

          <section className={ s.section }>
            <ListTallItem
              key={ setup[1].key }
              to={ setup[1].to }
              title={ setup[1].title }
              subtitle={ setup[1].subtitle }
              image={ setup[1].image }
              button={ true }
              buttonText="Learn More"
              external={ true }/>
          </section>

          <section className={ s.section }>
            <ListTallItem
              key={ setup[2].key }
              to={ setup[2].to }
              title={ setup[2].title }
              subtitle={ setup[2].subtitle }
              image={ setup[2].image }
              button={ true }
              buttonText="Learn More"
              external={ true }/>
          </section>


        </div>
      </div>
      <Footer/>
    </main>
  )
}

export default AdditionalResources
