import s from './SideBySide.module.css';
import ImgWithFallbackInMarkdown from './ImgWithFallbackInMarkdown';

const SideBySide = (props) => {
  const { children, src, alt, title } = props;

  return (
    <p className={ s.wrapper }>
      <ImgWithFallbackInMarkdown
        alt={ alt }
        className={ s.img }
        src={ src }
        title={ title }/>
      { children }
    </p> 
  )
}

export default SideBySide;