import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MapContainer, TileLayer, GeoJSON, Pane } from 'react-leaflet';
import ReactGA from 'react-ga4';
import { useMapEvents } from 'react-leaflet/hooks';
import L from 'leaflet';

import Sidebar from '../components/MapSidebar';
import Popup from '../components/MapPopup';
import Modal from '../components/Modal';
import FilterButton from '../components/MapFilterButton';
import { MAP_SETUP as DATA, FIELD_SETUP as FS } from '../data/setup';
import { convertJsonToGeojson, determineType, lookupColor, fetchAPIData } from '../functions/helpers';


import content from '../data/pageContent.json';
// import API_DATA from '../data/json/api.json';
import AQMA_BOUNDARIES from '../data/json/AQMA.json';
import DIFFUSION_TUBES from '../data/json/DIFFUSION_TUBES.json';
import LA_CENTROIDS from '../data/json/LA_CENTROIDS.json';
import LOCAL_AUTHORITIES from '../data/json/LOCAL_AUTHORITIES.json';



import 'leaflet/dist/leaflet.css';
import s from '../components/Map.module.css';



const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}


const Map = (props) => {
  let query = useQuery();

  // Map & Layer Refs
  const layerPointData = useRef(null);
  const layerLocalAuthorities = useRef(null);
  const layerLaLabels = useRef(null);
  const layerAqmaBoundaries = useRef(null);

  // Data Store
  const [map, setMap] = useState(null);
  const [diffusionTubes, setDiffusionTubes] = useState(null);
  const [laBoundaries, setLaBoundaries] = useState(null);
  const [laLabels, setLaLabels] = useState(null);
  const [aqmaBoundaries, setAqmaBoundaries] = useState(null);
  const [liveData, setLiveData] = useState(null);

  // Page State
  const [activeLayers, setActiveLayers] = useState(DATA.activeLayers);
  const [activePointLayer, setActivePointLayer] = useState(DATA.activePointLayer);
  const [currentPointData, setCurrentPointData] = useState(null);
  const [popupActive, setPopupActive] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(true);
  const [currentFeature, setCurrentFeature] = useState(null);
  const [modalActive, setModalActive] = useState(false);

  const page = content['map'];

  useEffect(() => {
    document.title = page['page-title'];
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);

  // On Page Load
  useEffect(() => {
    setLaBoundaries(LOCAL_AUTHORITIES);
    setLaLabels(LA_CENTROIDS);
    setAqmaBoundaries(AQMA_BOUNDARIES);
    const filteredPointData = handlePointData(DIFFUSION_TUBES, activePointLayer);
    setDiffusionTubes(DIFFUSION_TUBES);
    setCurrentPointData(filteredPointData);
    let width = window.innerWidth;
    // console.log(width);
    if (width < 577) {
      setSidebarActive(false);
    }
    if (sessionStorage.getItem("essexAirMapVisit") !== 'true') {
      sessionStorage.setItem("essexAirMapVisit", true);
      setModalActive(true);
    }
  }, [])


  // Fetch API Data
  useEffect(() => {
    fetchAPIData({
      account: process.env.REACT_APP_API_ACCOUNT,
      containerName: process.env.REACT_APP_API_CONTAINER_NAME,
      blobName: process.env.REACT_APP_API_BLOB_NAME,
      sas: process.env.REACT_APP_API_SAS_TOKEN
    })
    .then(response => {
      const parsedRes = JSON.parse(response)
      const output = convertJsonToGeojson(parsedRes);
      const filteredOutput = output.features.filter((proj) => {
        return proj.properties.siteName !== 'Council Offices';
      })

      setLiveData({
        type: 'FeatureCollection',
        features: filteredOutput
      });
    })

    
    // fetch('/api.json', { cache: "no-store" })
    //   .then(response => response.text())
    //   .then(response => {
    //     const parsedRes = JSON.parse(response);
    //     const output = convertJsonToGeojson(parsedRes);
    //     setLiveData(output);
    //     if (output?.features?.length === 0) return;
    //   })

  }, [convertJsonToGeojson])









  useEffect(() => {
    
    if (!layerPointData.current) return;
    // refreshStyle();
    // console.log('use effect based on active point layer change')

    // if (activePointLayer.timePeriod === 'Historical') {
    //   console.log('Historical Change')

    //   layerPointData.current.setStyle((feature) => {
    //     return { fillColor: lookupColor(feature.properties.VAL)}
    //   })
    // } else {
    //   console.log('Live Change')
    //   console.log(activePointLayer);
    //   const valueCol = FS.api[DATA.activePointLayer.pollutantType];
    //   // layerPointData.current.setStyle((feature) => {
    //   //   const fillColor = lookupColor(feature.properties[valueCol]);
    //   //   console.log(fillColor);
    //   //   return { fillColor }
    //   // })


    //   layerPointData.current.setStyle((feature) => {
    //     return { fillColor: lookupColor(feature.properties.pm10)}
    //   })

    // }


      // console.log(map.getPane('base-layers'))
      // console.log(map.getPane('top-layers'))

      // map.getPane('base-layers').style.zIndex = 400;
      // map.getPane('top-layers').style.zIndex = 401;
      // .style.zIndex = 400;
      // handleLayer('aqmaBoundaries', layerAqmaBoundaries)
      // console.log('Boundary Reorder')

      // `}
  }, [activePointLayer])






  // React Components
  const HandleLayerUpdate = (layer, data) => {
    useEffect(() => {
      if (!layer.current) return; 
      layer.current.clearLayers().addData(data);
      
      if (activePointLayer.timePeriod === 'Historical') {
        layerPointData.current.setStyle((feature) => {
          return { fillColor: lookupColor(feature.properties.VAL)}
        })
      } else {
        const valueCol = FS.api[activePointLayer.pollutantType];
        console.log({pType: activePointLayer.pollutantType, valueCol})
        layerPointData.current.setStyle((feature) => {
          const fillColor = lookupColor(feature.properties[valueCol]);
          return { fillColor }
        })
      }


        // layer.current.setStyle({fillColor :'blue'})

    }, [data])
  }

  HandleLayerUpdate(layerPointData, currentPointData)
  HandleLayerUpdate(layerLocalAuthorities, laBoundaries)
  HandleLayerUpdate(layerLaLabels, laLabels)
  HandleLayerUpdate(layerAqmaBoundaries, aqmaBoundaries)


  const HandleMapClick = () => {
    const map = useMapEvents({
      click(e) {
        if (e.originalEvent.target.nodeName !== 'path') {
          handlePopup();
        }
      }
    });
    return null;
  }


  // Handlers
  const handleModalActive = () => {
    console.log('modal toggle')
    setModalActive(!modalActive);
  }

  const handleMap = useCallback((node) => {
    if (node !== null) {
      setMap(node);
      // console.log(node)

      const areaBounds = JSON.parse(query.get('bounds'));

      const bounds = (areaBounds !== null) ? areaBounds : DATA.initialBounds;

      let width = window.innerWidth;
      if (width < 577) {
        node.fitBounds(bounds, { paddingBottomRight: [0, 0] })
      } else {
        node.fitBounds(bounds, { paddingBottomRight: [291, 0] })
      }
      // console.log(map.getPane('base-layers'))
      // .style.zIndex = 400;
      // node.getPane('top-layers').style.zIndex = 800;

    }

  }, [])

  const handleCurrentPointData = (toggle) => {
    console.log({toggle})

    console.log('handle current point data');
    const tempActive = { ...activePointLayer, timePeriod: toggle };
    let output = null;
    // No change of field referenced in data
    if (toggle === 'Historical') {
      console.log('handle diffusion tubes');
      output = handlePointData(diffusionTubes, tempActive)
    } else {
      console.log('handle api data');
      output = handlePointData(liveData, tempActive);
    }
    setCurrentPointData(output);
    setActivePointLayer(tempActive);
  }





  const handleYear = year => {
    setActivePointLayer({ ...activePointLayer, year });
    const output = handlePointData(diffusionTubes, { ...activePointLayer, year })
    console.log(output, { ...activePointLayer, year });
    setCurrentPointData(output);
  }

  const handleSidebar = () => {
    setSidebarActive(!sidebarActive);
    setPopupActive(false);
  }

  const handlePopup = (boolean = false) => {
    setPopupActive(boolean);
  }

  const handleGeoJSONClick = (e) => {
    setCurrentFeature(e);
    handlePopup(true);
  }

  const handleLayer = (layerName, layerRef) => {
    console.log(layerName, layerRef)
    if (activeLayers[layerName]) {
      console.log('Removing', layerName, 'Layer')
      setActiveLayers({ ...activeLayers, [[layerName]]: false })
      console.log(layerRef.current);
      map.removeLayer(layerRef.current);
    } else {
      console.log('Adding', layerName, 'Layer')
      setActiveLayers({ ...activeLayers, [[layerName]]: true })
      map.addLayer(layerRef.current);
      console.log(layerName);
      if (layerName === 'localAuthorityBoundaries' || layerName === 'aqmaBoundaries') {
        layerRef.current.bringToBack()
      }
    }
  }




  const handleCurrentPointDataAlt = (output) => {
    setCurrentPointData(output);

  }


  const handlePollutantType = pollutantType => {
    // sets pollutant type variable
    setActivePointLayer({ ...activePointLayer, pollutantType });

    // Retrieve full dataset
    let df = null;
    (activePointLayer.timePeriod === 'Historical') ? df = diffusionTubes : df = liveData;
    
    // if (!layerPointData.current) return;


    // NOTE - THIS DOES NOT WORK WITHOUT ASYNC, AS REF REFERENCED DOES NOT TRIGGER A RE-RENDER / AND 
    // THE DATA IS UPDATED PRIOR TO STATE ALTERATION BELOW. 
    
    console.log('layer refresh');
    // layerPointData.current.setStyle((feature) => {
    //   return { fillColor: lookupColor(feature.properties.pm10)}
    // })


    // REMOVE THIS LINE, AND THE ABOVE SECTION WILL WORK.
    console.log({ ...activePointLayer, pollutantType })
    const output = handlePointData(df, { ...activePointLayer, pollutantType })
    handleCurrentPointDataAlt(output)

  }



  // const refreshStyle = () => {
  //   console.log('Refresh Style');
  //   layerPointData.current.setStyle((feature) => {
  //     return { fillColor: lookupColor(feature.properties.pm10)}
  //   })
  // }



  const getCurrentValueField = (feature, currentPointLayer) => {
    // console.log(feature)
    const currentType = determineType(
      {
        layer: {
          feature
        }
      }
    );
    // console.log(currentType);
    // console.log(FS.api[info.pollutantType])
    if (currentType === 'diff') {
      return 'VAL';
    } else {
      return FS.api[currentPointLayer.pollutantType];
    }
  }



  const handlePointData = (inputData, filters) => {

    // console.log(filters.timePeriod);
    // Filtering for Historical Data
    if (filters.timePeriod === 'Historical') {
      const filteredFeatures = inputData.features.filter(d => {
        let maxSilderValue = true;
        let minSliderValue = true;
        // Check Truthy for Max Value on Slider
        if (filters.minMax[1] !== filters.maxNO2) {
          console.log(filters.minMax[1], filters.maxNO2)
          console.log('max not the same')
          maxSilderValue = d.properties.VAL <= filters.minMax[1];
        }
        // Check Truthy for Min Value on Slider
        minSliderValue = d.properties.VAL >= filters.minMax[0]
        
        // Apply all filters, returns true if feature to be added.
        return d.properties.YR === filters.year
          && d.properties.POL === filters.pollutantType
          && minSliderValue
          && maxSilderValue
      })
      return { ...DATA.geojsonStructure, features: filteredFeatures }
    }

    // Filtering for API Data
    else {
      const filteredFeatures = inputData.features.filter(d => {

        // console.log(d);

        let maxSilderValue = true;
        let minSliderValue = true;
        // Check Truthy for Dynamic Max Value on Slider
        if (filters.minMax[1] !== filters.maxNO2) {
          maxSilderValue = d.properties[FS.api[activePointLayer.pollutantType]] <= filters.minMax[1];
        }
        // Check Truthy for Dynamic Min Value on Slider
        minSliderValue = d.properties[FS.api[activePointLayer.pollutantType]] >= filters.minMax[0]
        
        // Apply all filters, returns true if feature to be added.
        return minSliderValue && maxSilderValue
      })
      return { ...DATA.geojsonStructure, features: filteredFeatures }
    }

  }

  const handleSlider = minMax => {
    setActivePointLayer({ ...activePointLayer, minMax });
    let df = null;
    (activePointLayer.timePeriod === 'Historical') ? df = diffusionTubes : df = liveData;
    const output = handlePointData(df, { ...activePointLayer, minMax });
    setCurrentPointData(output);
  }

  




  return (
    <div className={s.mapContainer}>
      {/* <button onClick={ () => refreshStyle() }>Style Alterations</button> */}

      {modalActive &&
        <Modal
          handleModalActive={handleModalActive}
          content={DATA.mapModal} />
      }

      <Sidebar
        active={sidebarActive}
        activeLayers={activeLayers}
        activePointLayer={activePointLayer}
        layerLocalAuthorities={layerLocalAuthorities}
        layerAqmaBoundaries={layerAqmaBoundaries}
        layerLaLabels={layerLaLabels}
        handleLayer={handleLayer}
        handleCurrentPointData={handleCurrentPointData}
        handlePollutantType={handlePollutantType}
        handleYear={handleYear}
        handleSlider={handleSlider}
        handleSidebar={handleSidebar} />

      <Popup
        feature={currentFeature}
        active={popupActive}
        handlePopup={handlePopup} 
        activePointLayer={ activePointLayer }/>

      <FilterButton
        onClick={handleSidebar} />


      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        scrollWheelZoom={true}
        className={s.leafletContainer}
        ref={handleMap}>

      <HandleMapClick />


      <TileLayer
        attribution={DATA.basemapAttribution}
        url={DATA.basemapTileLayer} />






      <Pane name='base-layers' style={{ zIndex: 300 }} />
      <Pane name='top-layers' style={{ zIndex: 31 }} />

      <GeoJSON
        pane="base-layers"
        data={laBoundaries}
        ref={layerLocalAuthorities}
        name={'localAuthorityBoundaries'}
        style={DATA.styleLaBoundaries} />

      <GeoJSON
        pane="base-layers"
        data={aqmaBoundaries}
        ref={layerAqmaBoundaries}
        style={DATA.styleAqmaBoundaries}
        name={'aqmaBoundaries'}
        eventHandlers={{ click: (e) => handleGeoJSONClick(e) }} />

      <GeoJSON
        pane="top-layers"
        data={diffusionTubes}
        ref={layerPointData}
        name={'diffusionTubes'}
        eventHandlers={{ click: (e) => handleGeoJSONClick(e) }}
        pointToLayer={(geoJsonPoint, latlng) => {
          const currentVal = getCurrentValueField(geoJsonPoint, activePointLayer)
          return L.circleMarker(latlng, { alt: 'point', ...DATA.stylePoints(geoJsonPoint.properties[currentVal]) });
        }} />

      <GeoJSON
        pane="top-layers"
        data={laLabels}
        ref={layerLaLabels}
        name={'laLabels'}
        pointToLayer={(geoJsonPoint, latlng) => {
          return L.marker(latlng,
            {
              icon: new L.DivIcon({
                className: 'abc',
                html: `<div class=${s.areaLabel}>${geoJsonPoint.properties.name}</div>`
              })
            })
        }} />



      </MapContainer>
    </div>
  )
}

export default Map