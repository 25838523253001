import React, { useEffect, useState } from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';
import NewsArticles from '../components/NewsArticles';
import ReactGA from 'react-ga4';

import { handleNews, handleEvents, fetchArticles, fetchEvents } from '../functions/cms';
import content from '../data/pageContent.json';


const News = (props) => {

  // Page State
  const [news, setNews] = useState(null);
  const [events, setEvents] = useState(null);


  // Page Constants
  const page = content['latest-news'];


  // On Page Loads
  useEffect(() => {
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    document.title = page['page-title'];
  }, [page]);


  // Fetch News
  useEffect(() => {
    fetchArticles(process.env.REACT_APP_NEWS_ARTICLES)
    .then(response => {
      const output = handleNews(response);
      setNews(output);
    })
    .catch (() => {
      console.log('Failed To Fetch Data')
    })
  }, [])


  // Fetch Events
  useEffect(() => {
    fetchEvents()
    .then(response => {
      // console.log(response);
      const output = handleEvents(response);
      setEvents(output);
    })
    .catch (() => {
      console.log('Failed To Fetch Data')
    })
  }, [])




  return (
    <main>
      <Header
        image={ page.image }
        wide={ true }
        title={ page.title }
        subtitle={ page.subtitle }/>

      <NewsArticles
        title={ 'All News' }
        type={ 'news' }
        data={ news }/>

      <NewsArticles
        title={ 'Events' }
        type={ 'events'}
        data={ events }/>

      <Footer/>
    </main>
  )
}

export default News
