import React from 'react';
import { Select as S, Tooltip } from 'antd';
import s from './Map.module.css';
import { MiniHelp } from '../data/icons';


const { Option } = S;


const Select = (props) => {

  const { title, options, defaultValue, onChange, icon, name, info=null } = props;

  return (
    <section className={ s.mapSection }>
      <div className={ s.split }>
        <div className={ s.label }>
          { icon }
          <label htmlFor={ name }>{ title }</label>
        </div>

        {info && <Tooltip
          placement="left"
          title={ info }>
          <div className={ s.info }>
            <MiniHelp/>
          </div>
        </Tooltip>}
      </div>
      <S name={ name } defaultValue={ defaultValue } style={{ width: '100%' }} onChange={ onChange }>
        { options.map(o => (
          <Option key={ o.value } value={ o.value }>{ o.name }</Option>
        ))}
      </S>
    </section>
  )
}

export default Select
