import React from 'react'
import s from './ListNews.module.css';
import ListTallItem from './ListTallItem';


const ListNews = (props) => {

  const { title, subtitle, url, image, to=null } = props;
  // <a href='https://www.cleanaircolchester.org/schools' target='_blank' rel="noreferrer">Clean Air Colchester</a> - provides schools with a range of activities and ideas for inspiring and involving pupils to understand about the air quality around them.

  return (
    <>
    { to &&
      <ListTallItem 
        title={ title }
        subtitle={ subtitle }
        to={ to }
        size={ 'small' }
        image={ image }
        button={ true }
      />
    }
    { !to &&
    <div className={ `${s.container}` }>
        <a href={ url } target='_blank' rel="noreferrer">
          <div className={ s.text }>
            <h2 className={ s.title }>{ title }</h2>
            <div className={ s.subTitle }>
              { subtitle }
            </div>
          </div>
        </a>
      </div>




    }
    </>
  )
}

export default ListNews



// <Link className={ `${s.container}`} to={ to }>
//   <div className={ s.text }>
//     <h2 className={ s.title }>{ title }</h2>
//     <div className={ s.subTitle }>
//       { subtitle }
//     </div>
//   </div>
// </Link>