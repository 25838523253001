import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import ListNews from './ListNews';

import s from '../pages/News.module.css';



const NewsArticles = (props) => {

  const { title, data, type, limit=null } = props;

  const [articlesOnDisplay, setArticlesOnDisplay] = useState(limit);
  const [totalArticles, setTotalArticles] = useState(0);
  const [newsArticles, setNewsArticles] = useState([]);


  useEffect(() => {
  
    let counter = 0;

    

    const generateNewsArticles = (df) => {
     
      // Check if dataset is present
      if (df?.length > 0) {
        setTotalArticles(df.length);
      }

      const articles = df.map(article => {
          counter = counter + 1;
          let inputProps = {};
          if (type==='news') {
            // Populate News Article Content
            inputProps = {
              key: article.url,
              to: '/latest-news/' + article.url,
              title: article.title,
              subtitle: article.bio,
              image: article.image
            }
          }
          else {
            // Populate Events Content
            inputProps = {
              key: article.staticwebobjectid,
              url: article.url,
              title: article.title,
              subtitle: article.bio,
              image: article.image
            }
          }
          
          
          // Renderer
          if (articlesOnDisplay === null || counter <= articlesOnDisplay) {
            return (
              <ListNews {...inputProps}/>
            ) 
          } else {
            return [];
          }
          
        }
      )
      return articles
    }

    if (data) {
      setNewsArticles(generateNewsArticles(data, limit))
    }

  }, [data, articlesOnDisplay, limit, type])



  const increaseArticlesOnDisplay = () => {
    setArticlesOnDisplay(articlesOnDisplay + limit)
  }



  return (
    <>
      <section>
        <h3 className={ 'section-header' }>{ title }</h3>
      </section>
      <section className={ s.section }>
        { data && newsArticles }
      { 
        totalArticles > articlesOnDisplay && 
        <div className={ s.addMore }>
          <Button size="large" onClick={() => increaseArticlesOnDisplay()}>
            Load More Articles
            <ArrowDownOutlined />
          </Button> 
        </div>
      }
      </section>
    </>
  )
}

export default NewsArticles
