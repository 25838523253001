import React from 'react';
import s from './ImageBanner.module.css';
import { ImgWithFallback, generateImageProps } from '../functions/cms';


const ImageBanner = (props) => {

  const { title, wide, image, backgroundColor=false } = props;

  const imageProps = generateImageProps(image);


  return (
    <>
    { 
      (!backgroundColor) && 
      <header className={ (wide) ? s.header + ' ' + s.wide : s.header  }>
        {
          image &&
          <>
            <div className={ s.imageContainer }>
              <ImgWithFallback
                className={ s.image }
                fileName={ imageProps.fileName }
                folder={ imageProps.folder }
                alt={ title }
                title={ title }/>
            </div>
          </>
        }
      </header>
    }


    {
      (backgroundColor) &&
      <header className={ (wide) ? `${s.header} ${s.wide} ${s.bg}` : s.header  }>
      {
        image &&
        <>
          <div className={ s.imageContainer }>
            <ImgWithFallback
              className={ s.image }
              fileName={ imageProps.fileName }
              folder={ imageProps.folder }
              alt={ title }
              title={ title }/>
          </div>
        </>
      }
      </header>
    }
    </>
  )
}

export default ImageBanner
