import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Footer from '../components/Footer';
import ListDualTallItem from '../components/ListDualTallItem';
import ListTallItem from '../components/ListTallItem';
import { Tabs } from 'antd';
import content from '../data/pageContent.json';
import s from './Educators.module.css';

const Educators = () => {

  const page = content['educators'];
  
  const [tabSelection, setTabSelection] = useState('1')



  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);

  

  
  
  

    const itemsPrimary = [
      {
        key: '/school-zone/spot-the-source',
        to: '/school-zone/spot-the-source',
        header: 'Interactive Game',
        title: 'Spot the Source',
        subtitle: (
          <>
            <p className={ s.subtitleHighlight }>4-11 years old</p>
            <p>Air pollution can come from different of sources in the world around us. Click on each of the sources of pollution in the different scenarios and avoid any non-pollution sources!</p>
          </>
        ),
        image: '/images/Shared/School/school_zone_primary_students.png',
        button: true
      },

      {
        title: 'We want to hear from you!',
        subtitle: (
          <>
            <>We would love to hear more about how you found our resources and how you used them! Please email us to provide feedback or share your photos and videos using the hashtag #STEAMEssexAir</>
            <a className={ s.contactInfo } href="mailto:contact@essexair.org.uk">contact@essexair.org.uk</a>
          </>
        ),
        image: '/images/Shared/School/school_zone_contact.png',
        button: true
      },

      {
        key: '/school-zone/educators',
        to: 'https://cdn.cms42.com/essexair/resources/SchoolZone/Designing_a_Change_Educator.pdf',
        title: 'Designing a Change!',
        subtitle: "Everyone has a part to play in reducing air pollution! Use your creativity to produce a 'No Idling' or 'Car Sharing' campaign poster to spread the word about the impacts of air pollution and the things people can do to improve air quality.        ",
        image: '/images/Shared/School/school_zone_activity_01.png',
        button: true
      },

      {
        key: '/school-zone/learners',
        to: 'https://cdn.cms42.com/essexair/resources/SchoolZone/Keep_Out_Educator.pdf',
        title: 'Keep Out!',
        subtitle: "Did you know that some types of air pollution are more dangerous than others? Use the 'Particle Sieving Experiment' to see how different size particles can impact our health.",
        image: '/images/Shared/School/school_zone_activity_02.png',
        button: true
      },
    ]
       

    const itemsSecondary = [
      {
        key: '/school-zone/harmful-vs-harmless-emissions',
        to: '/school-zone/harmful-vs-harmless-emissions',
        header: 'Interactive Game',
        title: 'Harmful vs Harmless Emissions',
        subtitle: (
          <>
            <p className={ s.subtitleHighlight }>11-18 years old</p>
            <p>There are many different substances found in the air we breathe. While some are natural and support life, we must watch out for harmful, air pollutants. Click on the various emissions found around the city and try to identify if they are harmless, or harmful!</p>
          </>
        ),
        image: '/images/Shared/School/school_zone_secondary_students.png',
        button: true,
      },

      {
        title: 'We want to hear from you!',
        subtitle: (
          <>
            <>We would love to hear more about how you found our resources and how you used them! Please email us to provide feedback or share your photos and videos on social media using the hashtag #STEAMEssexAir</>
            <a className={ s.contactInfo } href="mailto:contact@essexair.org.uk">contact@essexair.org.uk</a>
          </>
        ),
        image: '/images/Shared/School/school_zone_contact.png',
        button: true
      },

      {
        key: '/school-zone/educators',
        to: 'https://cdn.cms42.com/essexair/resources/SchoolZone/Finding_the_Source_Educator.pdf',
        title: 'Finding the Source!',
        subtitle: "To improve air pollution we need to understand patterns and find key problem areas in the world around us! Explore air pollution levels in your local area using mapping data to identify sources and explain trends.",
        image: '/images/Shared/School/school_zone_activity_01.png',
        button: true, 
        buttonText: "Download Resources"
      },

      {
        key: '/school-zone/learners',
        to: 'https://cdn.cms42.com/essexair/resources/SchoolZone/Lets_Innovate_Educator.pdf',
        title: "Let's Innovate!",
        subtitle: "Innovation and creativity are at the heart of improving air pollution! Explore and share how you could drive change and reduce air pollution in your local area by transforming the transport system.",
        image: '/images/Shared/School/school_zone_activity_02.png',
        button: true,
        buttonText: "Download Resources"
      },
    ]



    const tabItems = [
      {
        key: '1',
        label: 'Primary students',
        children: '',
      },
      {
        key: '2',
        label: 'Secondary students',
        children: '',
      }
    ]
    



  return (
    <main>
     



      <div className={ 'body' }>
        <div className={ 'body-container' }>

          <section className={ s.section }>
            <h3 className={ 'section-header' }>Educators</h3>

            <Tabs
              defaultActiveKey="1" 
              items={ tabItems } 
              onChange={ setTabSelection }
              centered/>
          </section>




{/* Tab 1 */}

          { tabSelection === "1" && <div className={ s.section1 }>

            <section className={ s.section }>
              <h3 className={ 'section-header' }>{ itemsPrimary[0].header }</h3>
              <ListTallItem
                key={ itemsPrimary[0].key }
                to={ itemsPrimary[0].to }
                subtitle={ itemsPrimary[0].subtitle }
                subtitleOther={ true }
                image={ itemsPrimary[0].image }
                button={ true }
                title={ itemsPrimary[0].title }
                buttonText={ "Play the interactive game" }/>
            </section>



            <section className={ s.section }>

              <h3 className={ 'section-header' }>Activities for the classroom</h3>
      
              <div className={ s.articles }>
                <ListDualTallItem
                  key={ itemsPrimary[2].key }
                  to={ itemsPrimary[2].to }
                  title={ itemsPrimary[2].title }
                  subtitle={ itemsPrimary[2].subtitle }
                  image={ itemsPrimary[2].image }
                  buttonText={ "Download resources" }
                  external={ true }/>
              
                <ListDualTallItem
                  key={ itemsPrimary[3].key }
                  to={ itemsPrimary[3].to }
                  title={ itemsPrimary[3].title }
                  subtitle={ itemsPrimary[3].subtitle }
                  image={ itemsPrimary[3].image }
                  buttonText={ "Download resources" }
                  external={ true }/>
              </div>

            </section>


            <section className={ s.section }>
              <h3 className={ 'section-header' }>{ itemsPrimary[1].title }</h3>
              <ListTallItem
                displayTitle={ false }
                title={ itemsPrimary[1].title }
                subtitle={ itemsPrimary[1].subtitle }
                image={ itemsPrimary[1].image }
                button={ false }
                isInteractive={ false }/>
            </section>

          </div>}








{/* Tab 2 */}

          { tabSelection === "2" && <div className={ s.section1 }>

            <section className={ s.section }>
              <h3 className={ 'section-header' }>{ itemsSecondary[0].header }</h3>
              <ListTallItem
                key={ itemsSecondary[0].key }
                to={ itemsSecondary[0].to }
                title={ itemsSecondary[0].title }
                subtitle={ itemsSecondary[0].subtitle }
                subtitleOther={ true }
                image={ itemsSecondary[0].image }
                button={ true }
                buttonText={ "Play the interactive game" }/>
            </section>



            <section className={ s.section }>

              <h3 className={ 'section-header' }>Activities for the classroom</h3>

              <div className={ s.articles }>
                <ListDualTallItem
                  key={ itemsSecondary[2].key }
                  to={ itemsSecondary[2].to }
                  title={ itemsSecondary[2].title }
                  subtitle={ itemsSecondary[2].subtitle }
                  image={ itemsSecondary[2].image }
                  buttonText={ "Download resources" }
                  external={ true }/>
              
                <ListDualTallItem
                  key={ itemsSecondary[3].key }
                  to={ itemsSecondary[3].to }
                  title={ itemsSecondary[3].title }
                  subtitle={ itemsSecondary[3].subtitle }
                  image={ itemsSecondary[3].image }
                  buttonText={ "Download resources" }
                  external={ true }/>
              </div>

            </section>


            <section className={ s.section }>
              <h3 className={ 'section-header' }>{ itemsSecondary[1].title }</h3>
              <ListTallItem
                key={ itemsSecondary[1].url }
                to={ itemsSecondary[1].url }
                subtitle={ itemsSecondary[1].subtitle }
                image={ itemsSecondary[1].image }
                button={ false }
                isInteractive={ false }/>
            </section>

            </div>}


        </div>
      </div>
      
      <Footer/>
    </main>
  )
}

export default Educators
