import s from './Accreditations.module.css';
import { ImgWithFallback, generateImageProps } from '../functions/cms';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const Accreditations = () => {

  const imageRJ = generateImageProps('/images/Shared/OtherLogos/RJEssexCC_colourLogos.png');
  const imageEH = generateImageProps('/images/Shared/OtherLogos/Essex_Highways_green2021.png');
  const imageDEFRA = generateImageProps('/images/Shared/OtherLogos/Defra.png');

  const imageRingwayJacobs = generateImageProps('/images/Shared/OtherLogos/RingwayJacobs.jpg')
  const imageECC = generateImageProps('/images/Shared/OtherLogos/EssexCountyCouncil.jpg')

  const links = [
    'https://www.essexhighways.org/',
    'https://www.ringway-jacobs.co.uk/',
    'https://www.essex.gov.uk/',
    'https://www.gov.uk/government/organisations/department-for-environment-food-rural-affairs' 
  ]

  const handlePageLink = (link) => {
    window.open(link, '_blank');
  }


  return (
    <>
      <section>
        <h3 className='section-header'>Accreditations</h3>
        <p>The Essex Air website has been developed by Essex Highways (a strategic partnership between Ringway Jacobs and elements of Essex County Council) and funded by Defra through the 2021/2022 Air Quality Grant scheme.</p>
        <div className={ s.logos }>
          <div className={ s.logo }>
              <div>
              <ImgWithFallback
                className={ s.image }
                fileName={ imageEH.fileName }
                folder={ imageEH.folder }
                alt={ 'Essex Highways' }
                title={ 'Essex Highways' }/>
              </div>
                <Button 
                aria-label="Find out more about Essex Highways" 
                className={ s.button } onClick={ () => handlePageLink(links[0]) } >Find out more <ArrowRightOutlined/></Button>
            </div>
          {/* <div className={ `${s.logo} ${s.middle}` }>
            <div>
              <ImgWithFallback
                className={ s.image }
                fileName={ imageRJ.fileName }
                folder={ imageRJ.folder }
                alt={ 'Ringway Jacobs and Essex County Council' }
                title={ 'Ringway Jacobs and Essex County Council' }/>

            </div>
              <a href="https://www.ringway-jacobs.co.uk/" target="_blank" rel="noreferrer" >
                <Button className={ s.button }>Find out more <ArrowRightOutlined/></Button>
              </a>
          </div> */}

          <div className={ s.logo }>
            <div>
              <ImgWithFallback
                className={ s.image }
                fileName={ imageRingwayJacobs.fileName }
                folder={ imageRingwayJacobs.folder }
                alt={ 'Ringway Jacobs' }
                title={ 'Ringway Jacobs' }/>

            </div>
              <Button aria-label="Find out more about Ringway Jacobs" 
              onClick={ () => handlePageLink(links[1]) } className={ s.button }>Find out more <ArrowRightOutlined/></Button>
           </div>

          <div className={ s.logo }>
            <div>
              <ImgWithFallback
                className={ s.image }
                fileName={ imageECC.fileName }
                folder={ imageECC.folder }
                alt={ 'Essex County Council' }
                title={ 'Essex County Council' }/>

            </div>
                <Button aria-label="Find out more about Essex County Council" 
                onClick={ () => handlePageLink(links[2]) } className={ s.button }>Find out more <ArrowRightOutlined/></Button>
          </div>

          <div className={ s.logo }>
            <div>
              <ImgWithFallback
                className={ s.image }
                fileName={ imageDEFRA.fileName }
                folder={ imageDEFRA.folder }
                alt={ 'Department for Environment and Rural Affairs' }
                title={ 'Department for Environment and Rural Affairs' }/>

            </div>
              <Button 
              aria-label="Find out more about DEFRA" 
              onClick={ () => handlePageLink(links[3]) } className={ s.button }>Find out more <ArrowRightOutlined/></Button>
          </div>
        </div>
      </section>
    </>
  )
}

export default Accreditations;