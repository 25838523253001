import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import ReactGA from 'react-ga4';
import Icon from '@ant-design/icons';

import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageBanner from '../components/ImageBanner';
import MapSection from '../components/MapSection';
import ReportSection from '../components/ReportSection';

import { fetchFiles, getAbsoluteEndpoint, fetchAuthorityData } from '../functions/cms';

import s from './LocalAuthority.module.css';

import AQMA_BOUNDARIES from '../data/json/AQMA.json';
import localAuthorities from '../data/localAuthorities.json';
import content from '../data/pageContent.json';



import { More } from '../data/icons';


const LocalAuthority = () => {

  
  const page = content['local-authority'];
  
  const [laMetadata, setLaMetadata] = useState(localAuthorities.template);
  const [mapTabs, setMapTabs] = useState();
  const [pageContent, setPageContent] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [reports, setReports] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();


  
  

  // On Page Load
  useEffect(() => {
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    const getMetadata = las => {
      const output = las.authorities.filter(a => {
        return a.name === id;
      })
      if (output.length === 0) return [];
      return output[0];
    }

    // Get Local Authority Metadata
    const la = getMetadata(localAuthorities);
    setLaMetadata(la);
    

    // Redirect if Invalid
    if (la.length === 0) return navigate('/404');


    // Handle data for 4 pages
    const handleData = async () => {
      return await fetchAuthorityData(la.reportsID, la.siteID)
      .then(
        res => {
          setPageContent(res)
      })
    }
    handleData()



    // Filter to Local Authority AQMA Bounds
    const bounds = AQMA_BOUNDARIES.features.reduce((acc, a) => {
      if (a.properties.local_auth === la.aqma) {
        acc.features.push(a)
      }
      return acc;
    }, {
      "type": "FeatureCollection",
      "name": "diffusion",
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": []
    })

    const orderedBounds = bounds.features.sort((a,b) => (a.properties.title > b.properties.title) ? 1 : ((b.properties.title > a.properties.title) ? -1 : 0))


    // For each AQMA, generate a map section
    let counter = 0;
    const mapTabs = orderedBounds.reduce((acc, a) => {
      counter = counter + 1;

      acc.push({
        label: a.properties.title,
        key: counter,
        children: (
          <MapSection
            aqmaName={ la.aqma }
            bounds={ JSON.parse(a.properties.envelope) }
            // DATA.aqmaBounds[a.properties.title].bounds
            laName={ la.la }
            loc={ a.properties.envelope }/>
        ),
      });
      return acc;
    }, []);

    // Set Map Content
    setMapTabs(mapTabs);

    
    // Alter the format of the Reports from CMS
    const cleanupReports = (df, name) => {
      return df.reduce((acc, i) => {
        const output = {
          key: i.id,
          name: i.title,
          url: getAbsoluteEndpoint(i.path, name),
          type: "PDF"
        }
        acc.push(output);
        return acc;
      }, [])
    }

    // Fetch and Set Reports in State
    const reportList = async () => {
      await fetchFiles(la.reportsID, la.siteID)
      .then(res => {
        return cleanupReports(res, '/' + la.name);
      })
      .then(res => {
        setReports(res);
      })
    }

    reportList()

  }, [id, navigate])


  // // Fetch and Set State of 4 pages of Authority content from CMS
  // useEffect(() => {
  //   // const n = location.pathname.lastIndexOf('/');
  //   // const loc = location.pathname.substring(n);

  //   const handleData = async () => {
  //     return await fetchAuthorityData()
  //     .then(
  //       res => {
  //         setPageContent(res)
  //     })
  //   }
  //   handleData()
  // }, [location])



  const handleTab = (e) => {
    setActiveTab(e);
  }





  const filterData = (df, field) => {
    const filteredData = df.filter(d => {
      return d.title === field
    })
    return filteredData[0]?.data;
  }

  return (
    <>
      <main>

        
      <ImageBanner
        wide={ true }
        title={ laMetadata.title }
        subtitle={ page.subtitle }
        image={ laMetadata.banner }/>

      <Header
        wide={ false }
        subtitle={ laMetadata.bio }
        title={ laMetadata.title }
        titleUpper={ false }
        titleLower={ true }
        banner={ false }>
          <Tabs
              centered
              size={'large'}
              className={ s.tabs }
              defaultActiveKey="1"
              onChange={ handleTab }
              items={[
                {
                  label: `About`,
                  key: '1',
                  children: <></>,
                },
                {
                  label: `Reports`,
                  key: '2',
                  children: <></>,
                },
                {
                  label: `AQMAs`,
                  key: '3',
                  children: <></>,
                },
                {
                  label: `Contact Us`,
                  key: '4',
                  children: <></>,
                },
              ]}
            />
            </Header>

          <div className={ 'body' }>
            { pageContent && 
              <div className={ 'body-container' }>

                {
                  activeTab==='1' && 
                  <>
                    <section className={ s.section }>
                      <h3 className='section-header-short'>About</h3>
                      { filterData(pageContent, 'About') && filterData(pageContent, 'About')  } 
                    </section>
                  </>
                }

                {
                  activeTab==='2' && 
                  <>
                    <section className={ s.section }>
                      <h3 className='section-header-short'>Reports</h3>
                      { filterData(pageContent, 'Reports') && filterData(pageContent, 'Reports') }
                    <ReportSection
                      wide={ true }
                      data={ reports }/>
                      </section>
                  </>
                }

                {
                  activeTab==='3' && 
                  <>
                    <section className={ s.section }>
                      <h3 className='section-header-short'>AQMAs</h3>
                      { filterData(pageContent, 'AQMA') && filterData(pageContent, 'AQMA')  } 
                    </section>
                    <Tabs
                      moreIcon={ <Icon component={ More } /> }
                      className={ s.mapTabs }
                      defaultActiveKey="1"
                      items={ mapTabs }/>
                  </>
                }



                {
                  activeTab==='4' && 
                  <>
                    <section className={ s.section }>
                      <h3 className='section-header-short'>Contact Us</h3>
                      { filterData(pageContent, 'Contact Us') && filterData(pageContent, 'Contact Us')  } 
                    </section>
                  </>
                }



              </div>
            }
          </div>

        </main>
      <Footer/>
    </>
  )
}

export default LocalAuthority;
