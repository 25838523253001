import React from 'react';
import { Switch, Slider, Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons';
import { MAP_SETUP as DATA } from '../data/setup';
import { Calendar, Clock, Layer, Pollutant, Arrow, Abc, MiniHelp } from '../data/icons';
import Select from './MapSelect';
import MapLegend from './MapLegend';
import { calculateUnits } from '../functions/helpers';


import s from './Map.module.css';

const Sidebar = (props) => {

  const {
    handleLayer,
    handleCurrentPointData,
    handlePollutantType,
    handleSlider,
    handleYear,
    handleSidebar,
    activeLayers,
    layerAqmaBoundaries,
    layerLocalAuthorities,
    layerLaLabels,
    activePointLayer,
    active
  } = props;


  return (
    <aside className={ (active) ? s.sidebar : s.sidebar + ' ' + s.sidebarHidden }>

      <header className={ s.header }>
        <div className={ s.sidebarCloser }>
          <Button
            className={ s.closerSidebar }
            shape="circle"
            icon={<CloseOutlined/>}
            onClick={ () => handleSidebar() }/>
        </div>

        <h1 className={ s.h1 }>{ DATA.mapTitle }</h1>
        <h2 className={ s.h2 }>{ DATA.mapBio }</h2>
        <h2 className={ `${s.h2} ${s.linkText }`}>For more information about the air pollutants, see our <Link to='/info' className={ `${s.linkText }`}>air quality information pages. </Link></h2>

      </header>



      <button className={ s.sidebarToggle } onClick={() => handleSidebar()}>
        <div className={`${s.toggleContainer} ${(active) ? '' : s.reverseArrow}` }>
          <Arrow/>
        </div>
      </button>


      <div className={ s.main }>

        {
          (activePointLayer.pollutantType !== 'PM2.5') &&
          <MapLegend level={ DATA.levels }/>
        }

        {
          (activePointLayer.pollutantType === 'PM2.5') &&
          <MapLegend level={ DATA.levelsPM25 }/>
        }

        <section className={ s.partition }>
          <h4 className={ s.sectionTitle }>Filters</h4>

          {/* Time Period */}
          <Select
            info={ DATA.mapInfo.timePeriod }
            name={ 'time' }
            icon={ <Clock col='var(--accent2)'/> }
            title={ 'Time Period' }
            options={ DATA.pointOptions }
            defaultValue={ activePointLayer.timePeriod }
            onChange={ handleCurrentPointData }/>


          {/* Pollutant Type */}
          <Select
            info={ DATA.mapInfo.pollutantType }
            name={ 'pollutant' }
            icon={ <Pollutant col='var(--accent2)'/> }
            title={ 'Pollutant Type' }
            options={ DATA.pollutantOptions }
            defaultValue={ activePointLayer.pollutantType }
            onChange={ handlePollutantType }/>


          {/* Year */}
          {
            (activePointLayer.timePeriod === 'Historical') &&
            <Select
              info={ DATA.mapInfo.year }
              name={ 'year' }
              icon={ <Calendar col='var(--accent2)'/> }
              title={ 'Year' }
              defaultValue={ activePointLayer.year }
              options={ DATA.yearOptions }
              onChange={ handleYear }/>
          }


          {/* Pollution Level Slider */}
          <section className={ s.slider }>
            <label className={ s.sectionTitle } htmlFor='slider'>
              <div>Pollution Level ({calculateUnits()})</div>
              <Tooltip
                placement="left"
                title={ DATA.mapInfo.pollutionLegend }>
                <div className={ s.info }>
                  <MiniHelp/>
                </div>
              </Tooltip>
          </label>
            <Slider
              name='slider'
              range
              marks={ (activePointLayer.pollutantType === 'PM2.5') ? DATA.sliderMarksPM25: DATA.sliderMarks }
              defaultValue={ (activePointLayer.pollutantType === 'PM2.5') ? DATA.minMaxPM25 : DATA.activePointLayer.minMax }
              onAfterChange={ handleSlider }
              max={ (activePointLayer.pollutantType === 'PM2.5') ? DATA.maxPM25 : DATA.activePointLayer.maxNO2 }/>
          </section>


        </section>

        <section className={ s.partition + " " + s.layers  }>
          <h4 className={ s.sectionTitle }>
            <div>Layers</div>
            <Tooltip
              placement="left"
              title={ DATA.mapInfo.layers }>
              <div className={ s.info }>
                <MiniHelp/>
              </div>
            </Tooltip>
          </h4>


          <div className={ s.option }>
            <div className={ s.label }>
              <Layer col='var(--accent2)'/>
              <label htmlFor='aqma'>AQMA Boundaries</label>
            </div>
            <Switch name='aqma' checked={ activeLayers.aqmaBoundaries } onChange={ () => handleLayer( 'aqmaBoundaries', layerAqmaBoundaries ) } />
          </div>

          <div className={ s.option }>
            <div className={ s.label }>
              <Layer col='var(--accent2)'/>
              <label htmlFor='local-a'>Local Authority Boundaries</label>
            </div>
            <Switch name='local-a' checked={ activeLayers.localAuthorityBoundaries } onChange={ () => handleLayer( 'localAuthorityBoundaries', layerLocalAuthorities ) } />
          </div>

          <div className={ s.option }>
            <div className={ s.label }>
              <Abc col='var(--accent2)'/>
              <label htmlFor='local-a-label'>Local Authority Labels</label>
            </div>
            <Switch name='local-a-label' checked={ activeLayers.laLabels } onChange={ () => handleLayer( 'laLabels', layerLaLabels ) } />
          </div>

        </section>



      </div>



    </aside>
  )
}

export default Sidebar


// <Select  defaultValue="a" style={{ width: 200 }} onChange={ handleChange }>
//   <Option value="a">Historical Data</Option>
//   <Option value="b">Live Data</Option>
// </Select>
