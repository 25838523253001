import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Tooltip } from 'antd';
import { calculateUnits } from "../functions/helpers";




import s from './Card.module.css';

const Card = (props) => {

  const { onClick, title, value, index, datetime, la, type } = props;


  // if (!val) return '';
  // if (type !== 'PM2.5') {
  //   return val >= 40 ? s.high :
  //   val >= 20 ? s.med :
  //   val > 1  ? s.low :
  //   ''
  // } else {
  //   return val >= 20 ? s.high :
  //   val >= 15  ? s.med :
  //   val > 1  ? s.low :
  //   ''
  // }



  // return val >= 40  ? s.high :
  //        val >= 20  ? s.med :
  //        val > 1  ? s.low :
  //        ''



  // const getBackgroundColor = (val) => {
  //   return val >= 40  ? 'var(--accent-high)' :
  //          val >= 20  ? 'var(--accent-medium)':
  //          val > 1  ? 'var(--accent-low)':
  //          ''
  // }

  // const getTextColor = (val) => {
  //   return val >= 40  ? 'var(--grey0)' :
  //          val >= 20  ? 'var(--grey10)':
  //          val > 1  ? 'var(--grey10)':
  //          ''
  // }





  const getBackgroundColorClass = (val, type) => {
    if (!val) return '';


    if (type === 'PM2.5') {
      return val >= 71 ? s.veryhigh :
        val >= 54 ? s.high :
        val >= 36 ? s.med :
        val > 1  ? s.low :
        s.low
    } else if (type === 'PM10') {
      return val >= 101 ? s.veryhigh :
      val >= 76 ? s.high :
      val >= 51 ? s.med :
      val > 1  ? s.low :
      s.low
    }
    else {
      return val >= 601 ? s.veryhigh :
        val >= 401 ? s.high :
        val >= 201 ? s.med :
        val > 1  ? s.low :
        s.low
    }
  }


  const getTotal = () => {
    if (type === 'PM2.5') {
      return 80;
    }
    else if (type === 'PM10') {
      return 110;
    }
    else {
      return 620;
    }
  }

  
  const generatePiePlot = () => {

    const COLORS = ["var(--accent-alpha-b)", 'var(--accent-alpha-a)'];


    let remainder = getTotal() - value;
    if (remainder < 0) remainder = 0;

    const df = [
      { name: 'A', value: value },
      { name: 'B', value: remainder },
    ]

    return (
        <div className={ s.plotContainer }>

          {
            (getTotal() !== null) &&
            <div className={ s.pieLabel }>
              <h2 className={ s.text }>{ index || 'Low' }</h2>
            </div>
          }
          <ResponsiveContainer>
            <PieChart
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
              <Pie
                data={ df }
                startAngle={ -135 }
                endAngle={ -405 }
                dataKey="value"
                innerRadius={ 33 }
                outerRadius={ 43 }
                fill="#82ca9d"
                strokeWidth={ 0 }>
                {df.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
    )
  }


  return (
    <Tooltip title={ datetime } placement="top" mouseLeaveDelay={0.05} color={ 'var(--main9)' }>
      <div
        onClick={() => onClick()}
        role="button"
        tabIndex={0}
        className={ s.card + ' noselect ' + getBackgroundColorClass(value, type) }>
        { generatePiePlot() }
        <div className={ s.description }>
            <h3 className={ s.value }>{ value }µg m<sup>3</sup> { calculateUnits(type) }</h3>
          <h2 className={ s.area }>{ title }</h2>
          <div className={ s.tag }>
            <h2 className={ s.la }>{ la }</h2>
          </div>
        </div>
      </div>
    </Tooltip>
  );
}

export default Card;



// <div>
//   <div>{title}</div>
//   <div style={{ backgroundColor: visible ? "transparent" : "gray" }}>
//     visible: {JSON.stringify(visible)}
//   </div>
//   <div>selected: {JSON.stringify(!!selected)}</div>
// </div>
// <div
//   style={{
//     backgroundColor: selected ? "green" : "bisque",
//     height: "200px"
//   }}
// />
