import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import ReactGA from 'react-ga4';

import ImageBanner from '../components/ImageBanner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ListDualItem from '../components/ListDualItem';

import info from '../data/infoArticles.json';
import content from '../data/pageContent.json';

import s from './Info.module.css';


const Info = (props) => {

  const page = content.info;

  const [activeTab, setActiveTab] = useState('A');


  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);


  // <article className={ s.article }>
  //   <Link to={ article.url } className={ s.link }>
  //     <h3>{ article.title }</h3>
  //     <h4>{ article.bio }</h4>
  //   </Link>
  // </article>
  const articlesA = info.articles.A.map(article => (
    <ListDualItem
      key={ article.url }
      to={ article.url }
      title={ article.title }
      // subtitle={ article.bio }
      // image={ '/images/Shared/Pollution/pollution1.jpg' }
      // image={ '/images/Shared/General/huts.jpg' }
      image={ article.image }

      />
  ))



  const articlesB = info.articles.B.map(article => (
    <ListDualItem
      key={ article.url }
      to={ article.url }
      title={ article.title }
      // subtitle={ article.bio }
      // image={ '/images/Shared/Pollution/pollution1.jpg' }
      // image={ '/images/Shared/General/huts.jpg' }
      image={ article.image }
      />
  ))


  const articlesC = info.articles.C.map(article => (
    <ListDualItem
      key={ article.url }
      to={ article.url }
      title={ article.title }
      // subtitle={ article.bio }
      // image={ '/images/Shared/Pollution/pollution1.jpg' }
      // image={ '/images/Shared/General/huts.jpg' }
      image={ article.image }

      />
  ))

  
  const articlesD = info.articles.D.map(article => (
    <ListDualItem
      key={ article.url }
      to={ article.url }
      title={ article.title }
      // subtitle={ article.bio }
      // image={ '/images/Shared/Pollution/pollution1.jpg' }
      // image={ '/images/Shared/General/huts.jpg' }
      image={ article.image }

      />
  ))


  const handleTab = (e) => {
    setActiveTab(e);
  }

  return (
    <>
      <main>

        <ImageBanner
          wide={ true }
          title={ page.title }
          subtitle={ page.subtitle }
          image={ page.image }/>
        <Header
          title={ page.title }
          subtitle={ page.subtitle }
          image={ page.image }>
          <div className={ s.tabContainer }>
          <Tabs
              centered
              size={'large'}
              className={ s.tabs }
              defaultActiveKey="A"
              onChange={ handleTab }
              items={[
                {
                  label: `What is air quality?`,
                  key: 'A',
                  children: <></>,
                },
                {
                  label: `How does air quality affect me?`,
                  key: 'B',
                  children: <></>,
                },
                {
                  label: `How is air quality measured?`,
                  key: 'C',
                  children: <></>,
                },
                {
                  label: `Where can I find out more?`,
                  key: 'D',
                  children: <></>,
                },
              ]}
            />
            </div>
          
        </Header>
          
        <div className={ 'body' }>
          <div className={ 'body-container' }>
          
            <section>
              {activeTab==='A' && <h3 className={ 'section-header' }>What is air quality?</h3> }
              {activeTab==='B' && <h3 className={ 'section-header' }>How does air quality affect me?</h3> }
              {activeTab==='C' && <h3 className={ 'section-header' }>How is air quality measured?</h3> }
              {activeTab==='D' && <h3 className={ 'section-header' }>Where can I find out more?</h3> }

            </section>

            <section className={ s.articles }>
              {activeTab==='A' && articlesA}
              {activeTab==='B' && articlesB}
              {activeTab==='C' && articlesC}
              {activeTab==='D' && articlesD}

            </section>
          </div>
        </div>
      </main>
      <Footer/>
    </>

  )
}

export default Info
