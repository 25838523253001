import React from 'react';
import { Button } from 'antd';
import { generatePiePlot, generateAreaPlot, lookupValue, reformatDate, determineType } from '../functions/helpers';
import { CloseOutlined } from '@ant-design/icons';
import DIFFUSION_TUBES from '../data/json/DIFFUSION_TUBES.json';
import s from '../components/Map.module.css';
import { MAP_SETUP as DATA, FIELD_SETUP as FIELDS } from '../data/setup';


const Popup = (props) => {

  const { feature, active, handlePopup, activePointLayer } = props;

  // console.log({activePointLayer})



  const elemType = determineType(feature)

  const isDiffusionTube = elemType === FIELDS.checks[0].type
  const isAPI = elemType === FIELDS.checks[2].type

  const parsePopup = (obj, allData) => {
    // console.log(obj, allData);

    const temp = allData.features.filter(f => {
      return f.properties.ID === obj.ID &&
             f.properties.POL === obj.POL &&
             f.properties.LA === obj.LA;
    })

    const plotData = temp.reduce((acc, f) => {
      if (f.properties.VAL !== null) {
        acc.push({year: f.properties.YR, value: f.properties.VAL})
      }
      acc = acc.sort((a,b) => a.year - b.year);
      return acc
    }, [])

    obj.PLOT = plotData
    return obj;
  }

  let tempContent = {}

  if (feature !== null) {
    tempContent = parsePopup(feature.layer.feature.properties, DIFFUSION_TUBES)
  }

  const body = Object.keys(tempContent).map(t => {
    if (DATA.lookupToRemove.includes(t)) return (<></>);
    if (tempContent[t] === '' || tempContent[t] === null) return (<></>);

    return (
      <li key={ t } className={ s.elem }>
        <h3 className={s.popupHeader}>{ lookupValue(DATA.lookup, t) }</h3>
        <h4 className={ s.val }>{ lookupValue(DATA.lookup, tempContent[t]) }</h4>
      </li>
    )
  })


  const areaPlot = generateAreaPlot(tempContent.PLOT, tempContent.POL)

  const piePlotDiff = generatePiePlot(tempContent.VAL, tempContent.POL,  tempContent.YR)


  // console.log({tempContent});
  const piePlotAPI = generatePiePlot(Math.round(tempContent[FIELDS.api[activePointLayer.pollutantType]] * 100) / 100 , activePointLayer.pollutantType)





  return (
    <div className={ active ? s.popup : s.popup + ' ' + s.popupHidden }>

      <div className={ s.header + ' ' + s.pHeader }>
        <div className={ s.lhs }>
          <h2 className={ s.pTitle }>{ tempContent[FIELDS.title[elemType]] }</h2>
        </div>
        <div className={ s.rhs }>
          <Button
            className={ s.closer }
            shape="circle"

            icon={ <CloseOutlined/> }
            onClick={ () => handlePopup() }/>
          { isDiffusionTube && piePlotDiff }
          { isAPI && piePlotAPI }
        </div>
      </div>

      <div className={ s.body }>

        { isDiffusionTube && areaPlot }


      { 
      !isAPI && <li className={ s.elem }>
        <h3 className={s.popupHeader}>Local Authority</h3>
        <h4 className={ s.val }>{ (isDiffusionTube) ? tempContent.LA: tempContent.local_auth }</h4>
      </li>
      }
      { body }

      {
        isDiffusionTube &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>Monitor Type</h3>
          <h4 className={ s.val }>{ lookupValue(DATA.lookup, tempContent.M) }</h4>
        </li>
      }

      {
        isDiffusionTube &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>Coordinates (BNG)</h3>
          <h4 className={ s.val }>{ `${tempContent.X}, ${tempContent.Y}`}</h4>
        </li>
      }

      <li className={ s.elem }>
        <h4 className={ s.val }>
          { !isAPI &&
            <a 
              target="_blank"
              rel="noreferrer"
              href={ lookupValue(DATA.lookup, (isDiffusionTube) ? tempContent.LA : "https://uk-air.defra.gov.uk/aqma/details?aqma_ref=" + tempContent.aqma_ref) }>
              Go to Data Source
            </a>
          }
        </h4>
      </li>


      {
        (isAPI && tempContent.latestMeasurementDateTime) &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>Latest Measurement Timestamp</h3>
          <h4 className={ s.val }>{ reformatDate(tempContent.latestMeasurementDateTime) }</h4>
        </li>
      }

      {
        (isAPI && tempContent[FIELDS.api.NO2]) &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>NO<sub>2</sub> Latest Reading</h3>
          <h4 className={ s.val }>{ Math.round(tempContent[FIELDS.api.NO2] * 100) / 100 } µg m<sup>3</sup></h4>
        </li>
      }

      {
        (isAPI && tempContent[FIELDS.api.PM10]) &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>PM<sub>10</sub> Latest Reading</h3>
          <h4 className={ s.val }>{ Math.round(tempContent[FIELDS.api.PM10] * 100) / 100 } µg m<sup>3</sup></h4>
        </li>
      }
    
      {
        (isAPI && tempContent[FIELDS.api['PM2.5']]) &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>PM<sub>2.5</sub> Latest Reading</h3>
          <h4 className={ s.val }>{ Math.round(tempContent[FIELDS.api['PM2.5']] * 100) / 100 } µg m<sup>3</sup></h4>
        </li>
      }
    
    
    {
        (isAPI && tempContent[FIELDS.api.PM10Daily]) &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>PM<sub>10</sub> Daily Average</h3>
          <h4 className={ s.val }>{ Math.round(tempContent[FIELDS.api.PM10Daily] * 100) / 100 } µg m<sup>3</sup></h4>
        </li>
      }

    
{
        (isAPI && tempContent[FIELDS.api['PM2.5Daily']]) &&
        <li className={ s.elem }>
          <h3 className={s.popupHeader}>PM<sub>2.5</sub> Daily Average</h3>
          <h4 className={ s.val }>{ Math.round(tempContent[FIELDS.api['PM2.5Daily']] * 100) / 100 } µg m<sup>3</sup></h4>
        </li>
      }



    
      </div>
    </div>
  )
}

export default Popup;
