import parse, { attributesToProps } from 'html-react-parser';

// REACT_APP_IMAGE_ENDPOINT = "https://cdn.cms42.com/essexair/"



export const fetchByEndpoint = async (endpoint) => {
  const output = await fetch("https://essexair-api.cms42.com/api/fe/v1.0/webobject/getbyfullurl" + endpoint)
  .then(response => response.json())
  .catch(err => {
    console.log(err);
  })
  return output
}

export const fetchArticles = async (endpoint) => {
  const output = await fetch("https://essexair-api.cms42.com/api/fe/v1.0/categories/getwebobjectsbycategory?id=" + endpoint)
  .then(response => response.json())
  .then(response => {
    const output = response.sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
    return output
  })
  .catch((err) => {
    console.log(err)
    return [];
  })
  return output
}


export const fetchEvents = async () => {

  const eventsURL = "https://essexair-api.cms42.com/api/fe/v1.0/webobject/getfiltereditems/events";
  let WEB_OBJECT_URL = new URL('https://essexair-api.cms42.com/api/fe/v1.0/webobject/get');
  
  // Get List of Events
  return await fetch(eventsURL)
    .then(response => response.json())
    .then(response => {
      response.forEach(val => WEB_OBJECT_URL.searchParams.append('ids', val)) 
      return WEB_OBJECT_URL;
    })
    // Get List of Data associated with the Events
    .then(response => fetch(response, {method: 'POST'}))
    .then(response => response.json())
    .catch(err => {
      console.log(err);
    })

}


export const fetchFiles = (endpoint, siteID=process.env.REACT_APP_LA_DEFAULT) => {

  const myHeaders = new Headers();
  myHeaders.append("SiteID", siteID);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  const output = fetch(process.env.REACT_APP_FILES_ENDPOINT + endpoint, requestOptions)
  .then(response => response.json())
  .then(output => {
    return JSON.parse(output.fieldValues[0].value)} )
  .catch(err => {
    console.log(err);
    return []
  })
  return output
}


export const generateImageProps = (img) => {
  if (!img) return;
  const noFileExtension = img.slice(0, img.lastIndexOf('.'));
  const lastSlash = img.lastIndexOf('/');
  const fileName = img.substring(lastSlash + 1);
  const folder = noFileExtension.slice(0, noFileExtension.lastIndexOf('/')) + '/';
  return { fileName, folder }
}


export const ImgWithFallback = ({
  fileName,
  folder,
  alt,
  ...props
}) => {
  const cleanFileName = fileName.slice(0, fileName.lastIndexOf('.'));
  return (
    <picture>
      <source srcSet={process.env.REACT_APP_DIRECT_ENDPOINT + folder + '_webp/' + cleanFileName + '/original.webp' } type='image/webp' alt={ props.alt }/>
      <img srcSet={process.env.REACT_APP_DIRECT_ENDPOINT + folder + fileName} alt={ alt } { ...props } />
    </picture>
  );
};


export const handleArticles = (input) => {
  const output = input.reduce((acc, i) => {
    // const imageSRC = await getImageSizes(input.image);
    const temp = i.image.replace(/\/uploads/g, '');
    const imageSRC = (i.image === '') ? null : temp;
    // console.log(i);
    const article = {
      title: i.title,
      bio: i.synopsis,
      image: imageSRC,
      url: i.url,
      type: 'cms'
    }
    acc.push(article)
    return acc;
  }, [])
  return output;
}

export const handleNews = (input) => {
  const output = input.reduce((acc, i) => {
    // console.log(i);

    const article = {
      title: i.title,
      bio: i.synopsis,
      url: i.url,
      image: i.image.substring(8)
    }
    acc.push(article)
    return acc;
  }, [])
  return output;
}


export const handleEvents = (input) => {
  const output = input.reduce((acc, i) => {
    console.log(i);

    const urlObj = i.fieldValues.filter(f => {
      return f.displayname === 'URL';
    })[0]

    console.log(urlObj);
    const article = {
      title: i.title,
      bio: i.synopsis,
      url: urlObj.value
    }
    acc.push(article)
    return acc;
  }, [])
  return output;
}

export const handleContent = (input) => {
  if (!input) return;
  const content = JSON.parse(input);
  // console.log(content);

  const outputString = content.reduce((acc, proj) => {
    proj.columns.forEach(column => {
      // console.log(column.content);
      if (column.type === 'WYSIWYG') {
        // console.log(column);
        acc = acc.concat(column.content)
      }
      else {
        // console.log(column)
      }
    })
    return acc;
  }, '')
  const output = wysiwyg(outputString);
  return output;
}

export const wysiwyg = (content) => {
  return parse(content, {
    replace: domNode => {
      if (domNode.name === 'source') {
        const props = attributesToProps(domNode.attribs);
        let srcSet = domNode.attribs.srcset;
        // console.log(srcSet);
        return <source {...props} srcSet={ srcSet }/>;
      }
    }
  });
  // return parse(content);
}


export const getAbsoluteEndpoint = (urlLocation, prefix='') => {
  return urlLocation.replace(/\/uploads/g, process.env.REACT_APP_DIRECT_ENDPOINT + prefix);
}


export const fetchContent = async endpoint => {
  // console.log(endpoint);
  return fetchByEndpoint(endpoint)
    .then(response => {
      return handleContent(response.content);
    })
    .catch(
      err => {
        console.log(err)
        return;
        // return navigate("/404");
      }
    )
}



export const fetchAuthorityData = (reportsID, sID) => {

  const myHeaders = new Headers();
  myHeaders.append("SiteID", sID);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  const requests = [
    fetch(process.env.REACT_APP_LA_ABOUT, requestOptions)
      .then(res => res.text()),

    fetch(process.env.REACT_APP_FILES_ENDPOINT + reportsID, requestOptions)
      .then(res => res.json())
      .then(output => output.content),

    fetch(process.env.REACT_APP_LA_AQMAS, requestOptions)
      .then(res => res.text()),
      
    fetch(process.env.REACT_APP_LA_CONTACT, requestOptions)
      .then(res => res.text()),
  ]


  const output = Promise.allSettled(requests)
  .then(res => {
    return res.reduce((acc, r, i) => {
      if (i === 1) {
        try {
          const data = handleContent(r.value);
          acc.push({ title: 'Reports', data });
        } 
        catch (err) {
          console.log(err);
        }
      }
      else {
        try {
          const temp = JSON.parse(r.value);
          const title = temp.title;
          const data = handleContent(temp.content);
          acc.push({ title, data });
        } catch (err) {
          console.log(err);
        }
      }
      return acc
    }, [])
  })
  .catch(err => {
    console.log(err);
  })
  
  return output;
}




