import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { useNavigate } from 'react-router-dom';
import s from '../pages/MarkdownPage.module.css';
import ImgWithFallbackInMarkdown from './ImgWithFallbackInMarkdown';
import DualImage from './DualImage';

const MarkdownContent = (props) => {
  const { pathname } = props;

  const navigate = useNavigate();

  const [pageContent, setPageContent] = useState('');
  
  const pageOptions = {
    wrapper: React.Fragment,
    overrides: {
      img: {
        component: ImgWithFallbackInMarkdown
      },
      DualImage: {
        component: DualImage
      },
      h2: {
        props: {
          className: s.h2
        }
      },
      h3: {
        props: {
          className: s.h3
        }
      },
      h4: {
        props: {
          className: s.h4
        }
      },
      h5: {
        props: {
          className: s['image-title']
        }
      },
      table: {
        props: {
          className: s['table']
        }
      },
      ul: {
        props: {
          className: s.ul
        }
      }
    }
  }


  useEffect(() => {
    import(`../pages/markdown${pathname}.md`)
      .then(res => {
        fetch(res.default)
          .then(res => {
            return res.text()
          })
          .then(res => {
            setPageContent(res)
          })
    })
    .catch(
      err => {
        console.log(err)
        return navigate("/404");
      }
    )
  }, [navigate, pathname])





  return (
      <>
        <div className='markdown-wrapper'>
          <Markdown 
            children={ pageContent }
            options={ pageOptions }/>
        </div>
      </>
  )
}

export default MarkdownContent;
