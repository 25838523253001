import { generateImageProps } from '../functions/cms';
import s from './ImgWithFallbackInMarkdown.module.css';

const ImgWithFallbackInMarkdown = (props) => {
  const { src, marginTopBottom } = props;
  const imageProps = generateImageProps(src);

  const cleanFileName = imageProps.fileName.slice(0, imageProps.fileName.lastIndexOf('.'));

  return (
    <div className={ marginTopBottom ? s.wrapper : '' }>
      <picture>
        <source srcSet={ process.env.REACT_APP_DIRECT_ENDPOINT + imageProps.folder + '_webp/' + cleanFileName + '/original.webp' } type='image/webp' alt={ props.alt } />
        <img srcSet={ process.env.REACT_APP_DIRECT_ENDPOINT + imageProps.folder + imageProps.fileName } alt={ props.alt } { ...props } />
      </picture>
    </div>
  );
};

export default ImgWithFallbackInMarkdown;