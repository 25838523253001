import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import ReactGA from 'react-ga4';
import Footer from '../components/Footer';
import MarkdownContent from '../components/MarkdownContent';
import content from '../data/pageContent.json';
import s from './WhyIsAirQualityImportant.module.css';

const WhyIsAirQualityImportant = () => {


  const [tabSelection, setTabSelection] = useState('quality');
  

  const page = content['why-is-air-quality-important'];

  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);


  const handleMainTab = (e) => {
    setTabSelection(e);
  }



  return (
    <main>
              



            <section className={ s.section }>
              <h3 className={ 'section-header' }>Why is air quality important?</h3>
              </section>

              <div className={ s.tabContainer }><Tabs
                centered
                size={ 'large' }
                className={ s.tabs }
                defaultActiveKey="emissions"
                onChange={ handleMainTab }
                items={[
                  {
                    label: `What is air quality?`,
                    key: 'quality',
                    children: <></>,
                  },
                  {
                    label: `What are air pollutants?`,
                    key: 'pollutants',
                    children: <></>,
                  },
                  {
                    label: `How does air quality affect me?`,
                    key: 'effects',
                    children: <></>,
                  },
                  {
                    label: `Where does air pollution come from?`,
                    key: 'where',
                    children: <></>,
                  },
                  {
                    label: `What can I do?`,
                    key: 'what',
                    children: <></>,
                  },
                
                ]}
              /></div>
            {/* </section> */}


            <div className={ 'body' }>
        <div className={ 'body-container' }>
          <div className='markdown-wrapper'>

        { 
          (tabSelection === 'quality') && 
          <section>
            <MarkdownContent pathname='/schoolZone/what-is-air-quality'/>
          </section>
        }
        { 
          (tabSelection === 'pollutants') && 
          <section>
            <MarkdownContent pathname='/schoolZone/what-are-air-pollutants'/>
          </section>
        }
        { 
          (tabSelection === 'effects') && 
          <section>
            <MarkdownContent pathname='/schoolZone/how-does-air-quality-affect-me'/>
          </section>
        }
        { 
          (tabSelection === 'where') && 
          <section>
            <MarkdownContent pathname='/schoolZone/where-does-air-pollution-come-from'/>
          </section>
        }
        { 
          (tabSelection === 'what') && 
          <section>
            <MarkdownContent pathname='/schoolZone/what-can-i-do'/>
          </section>
        }

           

          </div>
        </div>
      </div>
      <Footer/>
    </main>
  )
}

export default WhyIsAirQualityImportant
