import s from './Game.module.css';

const Game = (props) => {
  const { src } = props;
  
  return (
    <div className={ s.wrapper }>
        <iframe className={ s.iframe } src={ src } title="Game 2"/>
    </div>
  )
}

export default Game;