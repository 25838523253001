import s from './Blockquote.module.css';

const Blockquote = (props) => {

  const { children } = props;

  return (
    <div className={ s.wrapper }>
      <div className={ s.blockquote }>
      { children }
      </div>
    </div>
  )
}

export default Blockquote;