import React, { useEffect } from 'react';
import Header from '../components/Header';
import ReactGA from 'react-ga4';
import Footer from '../components/Footer';
import ImageBanner from '../components/ImageBanner';
import ListDualTallItem from '../components/ListDualTallItem';
import ListTallItem from '../components/ListTallItem';

import content from '../data/pageContent.json';
import s from './SchoolZone.module.css';

const SchoolZone = (props) => {

  const page = content['school-zone'];
  
  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);

  

  const pageSubtitle = (
    <>
      <>Do you want to find out more about air quality and why it's important? Whether you are an educator looking for activities and resources, or a learner looking for fun ways to expand your own knowledge, you've come to the right place to explore, learn and understand more about air quality, and why it matters.</>
      <br/><br/>
      <>The Essex Air Quality Consortium is on a mission to make the air we breathe in Essex cleaner and healthier. We created this website to help people learn more about air pollution and how they can help us in tackling it.</>
    </>
  )
  
  
  

    const setup = [
      {
        key: '/school-zone/why-is-air-quality-important',
        to: '/school-zone/why-is-air-quality-important',
        title: 'Why is air quality important?',
        subtitle: 'We think air quality is important and so should you. Explore the interactive games and activities below to find out why.',
        image: '/images/Shared/School/school_zone_importance.png',
        button: true
      },

      {
        key: '/school-zone/additional-resources',
        to: '/school-zone/additional-resources',
        title: 'Additional resources',
        subtitle: 'If you enjoyed these games and want to try even more, check out some of these additional resources, including the CAReless Campaign, Essex County Council and external resources.',
        image: '/images/Shared/School/school_zone_additional_resources.png',
        button: true
      },

      {
        title: 'We want to hear from you!',
        subtitle: (
          <>
            <>We would love to hear more about how you found our resources and how you used them! Please email us to provide feedback or share your photos and videos using the hashtag #STEAMEssexAir</>
            <a className={ s.contactInfo } href="mailto:contact@essexair.org.uk">contact@essexair.org.uk</a>
          </>
        ),
        image: '/images/Shared/School/school_zone_contact.png',
        button: true
      },

      {
        key: '/school-zone/educators',
        to: '/school-zone/educators',
        title: 'Educators',
        subtitle: "With STEAM lesson plans for primary and secondary, resource lists and activity sheets all mapped to the UN Sustainable Development Goals and Skills Builder Levels, you'll find activities and ideas on how to teach your students more about air quality and the science behind it.",
        image: '/images/Shared/School/school_zone_educators.png',
        button: true
      },

      {
        key: '/school-zone/learners',
        to: '/school-zone/learners',
        title: 'Learners',
        subtitle: "Are you at primary or secondary school and want to try some fun, educational activities focused on air and air quality? Check out our Learners' Zone!",
        image: '/images/Shared/School/school_zone_learners.png',
        button: true
      },
    ]







   return (
    <main>
      <ImageBanner
        wide={ true }
        title={ page.title }
        subtitle={ page.subtitle }
        image={ page.image }
        backgroundColor={ true }/>

      <Header
        image={ page.image }
        wide={ true }
        title={ page.title }
        subtitle={ pageSubtitle }
        invertText={ true }/>

      <div className={ 'body' }>
        <div className={ 'body-container' }>
          <section className={ s.section }>
            <h3 className={ 'section-header' }>
              { setup[0].title }
            </h3>
            <ListTallItem
              key={ setup[0].key }
              to={ setup[0].to }
              subtitle={ setup[0].subtitle }
              image={ setup[0].image }
              button={ true }
              displayTitle={ false }
              title={ setup[0].title }
              buttonText="Learn More"/>
          </section>



          <section className={ s.section }>
            <h3 className={ 'section-header' }>Want to learn more?</h3>
            <p>Interested in learning about air quality and want to try some fun games and activities to find out more? Follow the links below!</p>
            
            
            <div className={ s.articles }>
            
              <ListDualTallItem
                key={ setup[4].key }
                to={ setup[4].to }
                title={ setup[4].title }
                subtitle={ setup[4].subtitle }
                image={ setup[4].image }
                buttonText="Learn More"/>
            
              <ListDualTallItem
                key={ setup[3].key }
                to={ setup[3].to }
                title={ setup[3].title }
                subtitle={ setup[3].subtitle }
                image={ setup[3].image }
                buttonText="Learn More"/>
            

            </div>
          
          </section>

          <section className={ s.section }>
            <h3 className={ 'section-header' }>{ setup[1].title }</h3>
            <ListTallItem
              key={ setup[1].key }
              to={ setup[1].to }
              subtitle={ setup[1].subtitle }
              displayTitle={ false }
              title={ setup[1].title }
              image={ setup[1].image }
              button={ true }/>
          </section>

          <section className={ s.section }>
            <h3 className={ 'section-header' }>{ setup[2].title }</h3>
            <ListTallItem
              key={ setup[2].key }
              to={ setup[2].to }
              subtitle={ setup[2].subtitle }
              displayTitle={ false }
              title={ setup[2].title }
              image={ setup[2].image }
              button={ false }
              isInteractive={ false }/>
          </section>

          {/* <div className='markdown-wrapper'>
          </div> */}
        </div>
      </div>
      
      <Footer/>
    </main>
  )
}

export default SchoolZone
