import React, { useState, useEffect } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { Tooltip, Tabs } from "antd";
import { Info } from '../data/icons';

import { LeftArrow, RightArrow } from "./Arrows";
import Card from "./Card";
import s from './Scroller.module.css';

import useDrag from "./UseDrag";

const disclaimer = 'All "live" monitoring data presented on this website should be considered indicative only as it has not been checked or verified.  Furthermore, the data presented may not necessarily be "live" but rather the latest data currently available (e.g. due to equipment malfunction).'

const onWheel = (apiObj, ev) => {
  if (useDrag) {
    ev.stopPropagation();
    return;
  }
  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}


const Scroller = (props) => {

  const { items } = props;

  const [filteredItems, setFilteredItems] = useState([]);
  const [selected, setSelected] = useState("");
  const [pollutant, setPollutant ] = useState("NO2");

  const { dragStart, dragStop, dragMove, dragging } = useDrag();

  // On update of items, set filtered view
  useEffect(() => {
    setFilteredItems(filterItems("NO2"));
  }, [items])
  

  const filterItems = (val) => {
    if (val === 'all') return items;
    return items.filter(item => {
      return item.type === val
    })
  }



  const handleFilterItems = (toType) => {
    const output = filterItems(toType)
    console.log({output})
    setFilteredItems(output)
  }


  const handleDrag = ({ scrollContainer }) => (ev) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });


  const handleItemClick = (itemId) => () => {
    if (dragging) {
      return false;
    }
    setSelected(selected !== itemId ? itemId : "");
  };


  const onPollutionTypeChange = (e) => {
    setPollutant(e);
    const output = filterItems(e);

    setFilteredItems(output);
  }


  const tabs = [
    {
      key: 'NO2',
      label: (<>NO<sub>2</sub></>),
      children: (<p>Nitrogen dioxide (NO<sub>2</sub>) is a gas that is produced along with nitric oxide (NO) by combustion processes (i.e. burning of fossil fuels).</p>),
    },
    {
      key: 'PM10',
      label: (<>PM<sub>10</sub></>),
      children: (<p>Particulate matter (PM) is a generic term used to describe a complex mixture of solid and liquid particles of varying size, shape, and composition. PM is often classified according to its size. PM<sub>10</sub> is coarse particles which are less than 10 microns (µm) in diameter.</p>),
    },
    {
      key: 'PM2.5',
      label: (<>PM<sub>2.5</sub></>),
      children: (<p>Particulate matter (PM) is a generic term used to describe a complex mixture of solid and liquid particles of varying size, shape, and composition. PM is often classified according to its size. PM<sub>2.5</sub> is fine particles which are less than 2.5 microns (µm) in diameter.</p>),
    },
    {
      key: 'all',
      label: `All`,
      children: (<p>Nitrogen dioxide (NO<sub>2</sub>) is a gas that is produced along with nitric oxide (NO) by combustion processes (i.e. burning of fossil fuels). Particulate matter (PM) is a generic term used to describe a complex mixture of solid and liquid particles of varying size, shape, and composition. PM is often classified according to its size. PM<sub>10</sub> is coarse particles which are less than 10 microns (µm) in diameter. PM<sub>2.5</sub> is fine particles which are less than 2.5 microns (µm) in diameter.</p>),
    },
  ];




  return (
    <section className={ s.scrollerContainer }>
      <div className={ 'section-header ' + s.header }>
        <h3>Live Monitors</h3>
        <Tooltip title={ disclaimer } placement="top" mouseLeaveDelay={0.05} color={ 'var(--main9)' }>
          <span><Info col={'var(--grey6)'}/></span>
        </Tooltip>
      </div>
        <Tabs
          defaultActiveKey="NO2" 
          size='large'
          items={tabs} 
          onChange={ onPollutionTypeChange }
          className={ s.tabs }/>

      <div className={ s.scroller }>

        <div className="example">
          <div onMouseLeave={dragStop}>
            <ScrollMenu
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
              onWheel={onWheel}
              onMouseDown={() => dragStart}
              onMouseUp={() => dragStop}
              onMouseMove={handleDrag}
            >

              {filteredItems.map(({ name, value, index, type, datetime, la }) => (
                <Card
                  itemId={ name } // NOTE: itemId is required for track items
                  key={ name }
                  title={ name }
                  value={ value }
                  type={ type }
                  index={ index }
                  datetime={ datetime }
                  la={ la }
                  onClick={handleItemClick(name)}
                />
              ))}
            </ScrollMenu>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Scroller
