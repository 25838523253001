import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import ReactGA from 'react-ga4';

import Blockquote from '../components/Blockquote';
import DirectionalButtons from '../components/DirectionalButtons';
import DualImage from '../components/DualImage';
import Footer from '../components/Footer';
import Header from '../components/HeaderArticle';
import ImgWithFallbackInMarkdown from '../components/ImgWithFallbackInMarkdown';
import SideBySide from '../components/SideBySide';


import s from './MarkdownPage.module.css';


const MarkdownPage = (props) => {

  const [pageContent, setPageContent] = useState('');



  const location = useLocation();
  const navigate = useNavigate();


  


  const pageOptions = {
    wrapper: React.Fragment,
    overrides: {
      Header: {
        component: Header
      },
      
      DirectionalButtons: {
        component: DirectionalButtons
      },
      SideBySide: {
        component: SideBySide
      },
      DualImage: {
        component: DualImage
      },
      img: {
        component: ImgWithFallbackInMarkdown,
        props: {
          marginTopBottom: true
        }
      },
      blockquote: {
        component: Blockquote
      },
      p: {
        props: {
          className: s.para
        }
      },
      h2: {
        props: {
          className: s.h2
        }
      },
      h3: {
        props: {
          className: s.h3
        }
      },
      h4: {
        props: {
          className: s.h4
        }
      },
      h5: {
        props: {
          className: s['image-title']
        }
      },
      table: {
        props: {
          className: s['table']
        }
      },
      ul: {
        props: {
          className: s.ul
        }
      }
    }
  }




  useEffect(() => {
    window.scrollTo(0, 0);
    const properCase = (val) => {
        return val.replace(/\w\S*/g, (txt) => {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    };


    const lastSlash = location.pathname.lastIndexOf('/');
    const fileName = location.pathname.substring(lastSlash + 1);
    const name = properCase(fileName.replace(/-/g, ' '))
    document.title = name + ' | Essex Air';
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    
  }, [location, pageContent]);

  useEffect(() => {
    import(`./markdown${location.pathname}.md`)
      .then(res => {
        fetch(res.default)
          .then(res => {
            return res.text()
          })
          .then(res => {
            setPageContent(res)
          })
    })
    .catch(
      err => {
        console.log(err)
        return navigate("/404");
      }
    )
  }, [location, navigate])

  return (
      <>
        <main className='markdown-wrapper'>
            <Markdown
              children={ pageContent }
              options={ pageOptions }/>
        </main>
        <Footer/>
      </>
  )
}

export default MarkdownPage;
