import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import s from './Nav.module.css';


const NavLink = (props) => {

  const { to, label, handleMenuActive, type } = props;

  const toExclude = [
    '/info/what-can-i-do-to-reduce-my-emissions',
    '/info/how-can-i-protect-myself-and-family-in-my-own-home',
    '/info/how-can-i-protect-myself-and-my-family-when-i-am-out-and-about',
    '/info/what-can-i-do-to-reduce-my-emissions-in-my-own-home',
    '/info/what-can-i-do-to-reduce-my-emissions-when-i-am-out-and-about'
  ]

  const location = useLocation();

  const isActive = (path) => {
    // console.log(location);
    // console.log(type);

    if (type === 'exact') return path === location.pathname;

    if (type === 'multi') {
      const checker = toExclude.includes(location.pathname);
      const currentPath = '/' + location.pathname.split('/')[1];

      if (checker && path === currentPath) {
        return false
      }

      return path === currentPath;
    }



    const currentPath = '/' + location.pathname.split('/')[1];
    return path === currentPath;
  }

  return (
    <li className={ (isActive(to)) ? s.link + ' ' + s.activeLink : s.link }>
      <Link
        // ariaLabel={ label }
        to={to}
        onClick={ (e) => handleMenuActive(e, false) }>
        { label }
      </Link>
    </li>
  )
}

export default NavLink
