import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import s from './ListTriple.module.css';
import { ImgWithFallback, generateImageProps } from '../functions/cms';
import { ArrowRightOutlined } from '@ant-design/icons';


const ListTripleItem = (props) => {

  const { title, to, image=null } = props;

  const imageProps = generateImageProps(image);

  return (
    <Link className={ s.container } to={ to }>

      <div className={ s.imageContainer }>
        {
          image && <ImgWithFallback 
            className={ s.image }
            fileName={ imageProps.fileName }
            folder={ imageProps.folder }
            alt={ title }
            title={ title } />
        }
      </div>

      <div className={ s.right }>
        <div className={  s.text }>
          <h3 className={ s.title }>{ title }</h3>
        </div>
        <div>
          <Button className={ s.button }>Read More <ArrowRightOutlined/></Button>
        </div> 
      </div>

    </Link>
  )
}

export default ListTripleItem

// { image &&
// <img src={ image } className={ s.image } alt={ title } title={ title }/>
// }