import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

import ImageBanner from '../components/ImageBanner';
import Header from '../components/Header';
import content from '../data/pageContent.json';
import { Button } from 'antd';

import s from './LostAndFound.module.css';

const LostAndFound = (props) => {


  const page = content['lost-and-found'];

  useEffect(() => {
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    document.title = page['page-title'];
  }, [page]);

  return (
    <main>

      <ImageBanner
        wide={ true }
        title={ page.title }
        subtitle={ page.subtitle }
        image={ page.image }/>

      <Header
        image={ page.image }
        wide={ false }
        title={ page.title }
        subtitle={ page.subtitle }>
        <div className={ s.button }>
          <Button size='large'>
            <Link to='/'>
              Return to Homepage
            </Link>
          </Button>
        </div>
        </Header>
        
       </main>
  )
}

export default LostAndFound
