import React, { useEffect } from 'react'
import ReactGA from 'react-ga4';

import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageBanner from '../components/ImageBanner';
import ListTripleItem from '../components/ListTripleItem';


import localAuthorities from '../data/localAuthorities.json';
import content from '../data/pageContent.json';
import s from './LocalAuthority.module.css';

const LocalAuthorities = (props) => {

  const page = content['local-authorities'];

  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);

  const authorities = localAuthorities.authorities.map(article => (
    <ListTripleItem
      key={ article.url }
      to={ article.url }
      title={ article.title }
      subtitle={ article.bio }
      image={ article.image }
      />
  ))


  const subtitle = (
    <>
      <div className={ s.space }>Air pollution does not respect borough boundaries. In Essex the local authorities are varied in size and population, so the effective options related to individual borough activity are limited. All Essex authorities have therefore agreed to work in partnership with each other, alongside Essex County Council as the highway authority, to seek joint solutions to air pollution problems and share best practice. This partnership is known as the Essex Air Quality Consortium, which was formed in 1995.</div>
      <div>Click on the links below to find out more about air quality in your local area.</div>
    </>
  )
  return (
    <>
      <main>
        <ImageBanner
          wide={ true }
          title={ page.title }
          subtitle={ subtitle }
          image={ page.image }/>
        <Header
          title={ page.title }
          subtitle={ subtitle }/>

        <div className={ 'body' }>
          <div className={ 'body-container' }>
            <section className={ s.articles }>
              { authorities }
            </section>

          </div>
        </div>
      </main>
      <Footer/>
    </>
  )
}

export default LocalAuthorities;
