import React from 'react';
import s from './ModalCookies.module.css';
import { Button } from 'antd';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';


const ModalCookies = (props) => {

  const { handleCookiePolicyHidden } = props;

  const handleAcceptance = (accepted) => {
    // Send for current page hit
    if (accepted) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    const cookies = { accepted, hidden: true }
    handleCookiePolicyHidden(cookies.hidden)
    localStorage.setItem("cookiePreferences", JSON.stringify(cookies))
  }

  return (
    <div className={ s.modal }>
      <h2>Cookies Policy</h2>
      <p className={ s.bio }>We use cookies to give you the best possible experience on our website. For more information, please visit our <Link to={ '/cookies' }>cookies page</Link>.</p>
      <div className={ s.buttons }>
        <Button type="primary" onClick={ () => handleAcceptance(true) }>Accept Cookies</Button>
        <Button onClick={ () => handleAcceptance(false) }>Decline Cookies</Button>
      </div>


    </div>
  )
}

export default ModalCookies
