import React, { useState } from 'react';
import NavLink from './NavLink';
import s from './Nav.module.css';
import MenuButton from './MenuButton';
import { Link } from 'react-router-dom';
import ImgWithFallbackInMarkdown from './ImgWithFallbackInMarkdown';


const Nav = (props) => {
  const [menuActive, setMenuActive] = useState(true);


  const handleMenuActive = (e, bool) => {
    setMenuActive(!menuActive);
    // e.blur();
  }



  return (
    <nav className={ s.nav }>
      <Link to={'/'} className={ s.logoContainer }>
        <ImgWithFallbackInMarkdown
            className={ s.logo }
            alt={ 'Essex Air Consortium Logo' }
            src={ '/images/Shared/OtherLogos/logo.png' }/>
        
      </Link>
      <MenuButton aria-label="Toggle Navigation Menu" onClick={ handleMenuActive }/>
      <ul className={ (menuActive) ?  s.links  : s.links + ' ' + s.responsive }>

        <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/'}
          label={ 'Home' }/>

        {/* <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/latest-news'}
          label={ 'Latest News' }/> */}

        <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/info'}
          type={ 'multi' }
          label={ 'Air quality information' }/>
   
        <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/map'}
          label={ 'Air quality map' }/>
   
        <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/local-authorities'}
          label={ 'Local authorities' }/>


{/* 
        <NavLinkDropdown
          endpoint={ endpointsA }
          labels={ labelsA }
          handleMenuActive={ handleMenuActive }/>


        <NavLinkDropdown
          endpoint={ endpointsB }
          labels={ labelsB }
          handleMenuActive={ handleMenuActive }/> */}


        <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/what-can-i-do'}
          label={ 'What can I do?' }/>


        <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/school-zone'}
          label={ 'School zone' }/>

        <NavLink
          handleMenuActive={ handleMenuActive }
          to={'/case-studies'}
          label={ 'Case studies' }/>



      </ul>
    </nav>
  )
}

export default Nav

// <NavLink
//   handleMenuActive={ handleMenuActive }
//   to={'/monitoring-sites'}
//   label={ 'Essex Monitoring Sites' }/>
