import React from 'react';
import { Menu } from '../data/icons';
import s from './Nav.module.css';

const MenuButton = (props) => {

  const { onClick } = props;

  return (
    <div className={ s.menuBtnContainer }>
      <button className={ s.menuBtn } onClick={ onClick }>
        <Menu col='var(--grey0)'/>
      </button>
    </div>
  )
}

export default MenuButton
