import React from 'react';
import s from './ReportSection.module.css';
import Table from './Table';

const ReportSection = (props) => {

  const { title, data, body, wide } = props;

  const textBody = () => {

    if (Array.isArray(body)) {
      return body.map((a, i) => {
        return (<p  key={ i } className={ (wide) ? `${ s.p } ${ s.wide }`: s.p }>{ a }</p>)
      })
    } else {
      return (<p className={ (wide) ? `${ s.p } ${ s.wide }`: s.p }>{ body }</p>)
    }
  }

  return (
      <Table 
        data={ data }
        className={ s.reports }/>
  )
}

export default ReportSection
