import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4';

import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageBanner from '../components/ImageBanner';
import ListTallItem from '../components/ListTallItem';

import { handleArticles, fetchArticles } from '../functions/cms';
import content from '../data/pageContent.json';


const CaseStudies = (props) => {

  const [data, setData] = useState(null);

  const page = content['case-studies'];

  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);

  useEffect(() => {
    fetchArticles(process.env.REACT_APP_CASE_STUDIES)
    .then(response => {
      // console.log(response)
      const output = handleArticles(response);
      setData(output);
    })
    .catch (() => {
      console.log('Failed To Fetch Data')
    })
  }, [])


  const generateArticles = () => {
    // console.log(data);
    const articles = data.map(article => (
      <ListTallItem
        key={ article.url }
        to={ article.url }
        title={ article.title }
        subtitle={ article.bio }
        image={ article.image }
        type={ article.type }
        button={ true }
        />
    ))
    return articles
  }



  return (

    <>
      <main>
      <ImageBanner
          wide={ true }
          title={ page.title }
          subtitle={ page.subtitle }
          image={ page.image }/>
        <Header
          title={ page.title }
          subtitle={ page.subtitle }/>
        <div className={ 'body' }>
          <div className={ 'body-container' }>
        <section>
          { data && generateArticles() }
        </section>
        </div></div>
      </main>
      <Footer/>
    </>

  )
}

export default CaseStudies
