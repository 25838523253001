import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import s from '../components/Map.module.css';
import { API_SETUP as API, FIELD_SETUP as FIELDS } from '../data/setup';
import { BlobServiceClient } from '@azure/storage-blob';


export const lookupColor = (d, type='a') => {
  // console.log(d)
  if (type === 'a') {
    return d >= 40 ? 'var(--grad-1)' :
      d >= 36 ? 'var(--grad-2)' :
        d >= 30 ? 'var(--grad-3)' :
          d > 1 ? 'var(--grad-4)' :
            d === 0 ? 'var(--grey6)' :
              d === null ? 'var(--grey4)' :
                'var(--grey4)';
  }
  else {
    return d >= 20 ? 'var(--grad-1)' :
      d >= 18 ? 'var(--grad-2)' :
        d >= 15 ? 'var(--grad-3)' :
          d > 1 ? 'var(--grad-4)' :
            d === 0 ? 'var(--grey6)' :
              d === null ? 'var(--grey4)' :
                'var(--grey4)';

  }
}

export const lookupPieColor = (t) => {
  return [lookupColor(t), 'var(--main6)'];
}

export const lookupValue = (lookup, value) => {
  if (value in lookup) {
    return lookup[value]
  }
  return value;
}

const AxisLabel = ({ axisType, x, y, width, height, stroke, children, val }) => {
  const isVert = axisType === 'yAxis';
  const cx = isVert ? x : x + (width / 2);
  const cy = isVert ? (height / 2) + y : y + height + 10;
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  return (
    <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle" stroke={stroke}>
      {val}
    </text>
  );
};

const lookupLevels = units => {
  if (units !== 'PM2.5') {
    return [30, 36, 40];
  } else {
    return [15, 17, 19];

  }
}

const lookupRange = units => {
  if (units !== 'PM2.5') {
    return [0, 40];
  } else {
    return [0, 20];
  }
}

export const generateAreaPlot = (data, units) => {

  const level = lookupLevels(units);
  const range = lookupRange(units);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={s.tooltip}>
          <p>{payload[0].value}µg m<sup>3</sup></p>
        </div>
      );
    }

    return null;
  };


  return (
    <div className={s.plotContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 15,
            right: 20,
            left: 0,
            bottom: 5,
          }}>
          <XAxis
            dataKey="year"
            label={{ value: "Year", offset: -5, position: "insideBottom" }} />
          <YAxis
            label={<AxisLabel axisType='yAxis' x={25} y={90} width={0} height={0} val={units} />}
            domain={range} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            type="monotone"
            dataKey="value"
            stroke="var(--main3)"
            fill="var(--main3)"
            dot={true} />



          <ReferenceLine
            y={level[0]}
            stroke="var(--grad-3)"
            className={s.labelHigh}
            strokeWidth={2} />

          <ReferenceLine
            y={level[1]}
            stroke="var(--grad-2)"
            strokeWidth={2} />

          <ReferenceLine
            y={level[2]}
            stroke="var(--grad-1)"
            strokeWidth={2} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export const calculateUnits = (units = '') => {
  if (units === 'NO2') {
    return (<>NO<sub>2</sub></>);
  } else if (units === 'PM2.5') {
    return (<>PM<sub>2.5</sub></>);
  } else if (units === 'PM10') {
    return (<>PM<sub>10</sub></>);
  } else {
    return <>µg m<sup>3</sup></>
  }
}

export const generatePiePlot = (total, units, year) => {

  const COLORS = lookupPieColor(total);

  let remainder = 40 - total;
  if (remainder < 0) remainder = 0;

  const df = [
    { name: 'A', value: total },
    { name: 'B', value: remainder },
  ]

  const outputUnits = calculateUnits(units);




  return (
    <div className={s.plotContainer}>
      {
        (total !== null) &&
        <div className={s.pieLabel}>
          <h2 className={s.pieText + ' ' + s.pieYear}>{year}</h2>
          <h3 className={s.pieText}>{total} µg m<sup>3</sup></h3>
          <h2 className={s.pieText + ' ' + s.pieYear}>{outputUnits}</h2>

        </div>
      }

      {
        (total === null) &&
        <div className={s.pieLabel}>
          <h3 className={s.pieText} >No Data</h3>
          {year && <h2 className={s.pieText} >in {year}</h2>}
        </div>
      }
      <ResponsiveContainer>
        <PieChart
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
          <Pie
            data={df}
            startAngle={90}
            stroke='var(--main6)'
            endAngle={-270}
            dataKey="value"
            innerRadius={72}
            outerRadius={85}
            fill="#82ca9d"
            paddingAngle={1}>
            {df.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export const reformatDate = (t) => {

  let options = {  
      weekday: "short", month: "short",  
      day: "numeric", hour: "2-digit", minute: "2-digit"  
  };  
  
  if (t !== '' && t !== null) {
    return ( new Date(t).toLocaleDateString('en-GB', options) )
  }
}

export const convertJsonToGeojson = input => {
  if (!input || input.length === 0) return;

  const output = input.reduce((acc, i) => {
    const feature = {
      "type": "Feature",
      "properties": {...i},
      "geometry": {
        "coordinates": [
          i[API.fields?.lon], i[API.fields?.lat],
        ],
        "type": "Point"
      }
    }
    acc.features.push(feature);
    return acc;
  }, API.geojson)

  return output;
}

export const determineType = (f) => {
  if (f === null) return;
  const check = FIELDS.checks.filter(field => {
    return f.layer.feature.properties.hasOwnProperty(field.val) 
  })
  return check[0]?.type || null;
}

const calculateIndexString = (val, type) => {
  if (!val) return '';
  const options = ['Low', 'Medium', 'High', 'Very High'];
  
  if (type === 'PM2.5') {
    return val >= 71 ? options[3] :
      val >= 54 ? options[2] :
      val >= 36 ? options[1] :
      val > 1  ? options[0] :
      ''
  } else if (type === 'PM10') {
    return val >= 101 ? options[3] :
    val >= 76 ? options[2] :
    val >= 51 ? options[1] :
    val > 1  ? options[0] :
    ''
  }
  else {
    return val >= 601 ? options[3] :
      val >= 401 ? options[2] :
      val >= 201 ? options[1] :
      val > 1  ? options[0] :
      ''
  }


  // if (type !== 'PM2.5') {
  //   return val >= 40 ? options[2] :
  //   val >= 20  ? options[1] :
  //   val > 1  ? options[0] :
  //   ''
  // } else {
  //   return val >= 20 ? options[2] :
  //   val >= 15  ? options[1] :
  //   val > 1  ? options[0] :
  //   ''
  // }
}

export const compileLiveDataList = input => {
  if (input.length < 1) return [];
  

  const output = input.reduce((acc, i) => {
    // Catch if there are missing values 
    if (i.siteName === 'Council Offices') return acc;

    // if (!i.siteName) return acc;

    if (i.site_id === 'HOPE') {
      i.siteName = 'Stanford-le-Hope';
    } 
    else if (i.site_id === 'THUR') {
      i.siteName = 'Urban Background';
    } 
    else if (i.site_id === 'SEND') {
      i.siteName = 'Southend-on-Sea';
    } 
    else if (i.site_id === 'OSY') {
      i.siteName = 'St Osyth';
    }

    if (i.no2Latest && i.no2Latest !== -99) {
      acc.push({
        id: i.site_id,
        name: i.siteName,
        value: Math.round(i.no2Latest * 10) / 10,
        index: calculateIndexString(i.no2Latest, 'NO2'),
        type: 'NO2',
        datetime: reformatDate(i.latestMeasurementDateTime),
        la: i.localAuthority
      });
    }
 
    if (i.pm10DailyAvg && i.pm10DailyAvg !== -99) {
      acc.push({
        id: i.site_id,
        name: i.siteName,
        value: Math.round(i.pm10DailyAvg * 10) / 10,
        index: calculateIndexString(i.pm10DailyAvg, 'PM10'),
        type: 'PM10',
        datetime: reformatDate(i.latestMeasurementDateTime),
        la: i.localAuthority
      });
    }

    if (i.pm25DailyAvg && i.pm25DailyAvg !== -99) {
      acc.push({
        id: i.site_id,
        name: i.siteName,
        value: Math.round(i.pm25DailyAvg * 10) / 10,
        index: calculateIndexString(i.pm25DailyAvg, 'PM2.5'),
        type: 'PM2.5',
        datetime: reformatDate(i.latestMeasurementDateTime),
        la: i.localAuthority
      });
    }

    return acc
  }, [])
  

  const orderedOutput = output.sort((a, b) => (a.la > b.la) ? 1 : -1)
  // console.log({orderedOutput})

  return orderedOutput;
}

const blobToString = async (blob) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
}

export const fetchAPIData = async (details) => {
  try {
    const {account, containerName, blobName, sas} = details;
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sas}`);

    const main = async () => {
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlobClient(blobName);
      const downloadBlockBlobResponse = await blobClient.download();
      const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
      return downloaded;
    }
    return await main()
  }
  catch {
    return null
  }
}
