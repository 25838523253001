import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { fetchByEndpoint, handleContent } from '../functions/cms';
import ReactGA from 'react-ga4';

import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageBanner from '../components/ImageBanner';


const NewsArticle = (props) => {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [headerData, setHeaderData] = useState({
    title: '',
    subtitle: '',
    image: ''
  })

  useEffect(() => {
    fetchByEndpoint('/news/' + id)
    .then(response => {
      const image = response.image.substring(8);
      document.title = response.title + ' | Essex Air';
      window.scrollTo(0, 0);
      const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
      if ( cookies?.accepted ) {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }
      setHeaderData({
        title: response.title,
        subtitle: response.synopsis,
        image: image
      })
      setData(handleContent(response.content));
    })
    .catch(
      err => {
        console.log(err)
        return navigate("/404");
      }
    )
  }, [navigate, id])

  return (
    <>

    <main className='markdown-wrapper'>
      <ImageBanner
          title={ headerData.title }
          subtitle={ headerData.subtitle }
          image={ headerData.image }/>

      <Header
        wide={ true }
        title={ headerData.title }
        banner={ false }
        titleUpper={ false }
        titleLower={ false }
        content={ false }
        subtitle={ headerData.subtitle }
        section={ false }/>
      
      <div className={ 'body' }>
        <div className={ 'body-container' }>
          <section>
            <h3 className='section-header'>{headerData.title}</h3>
          </section>
      
          <section>
            { data && data }
          </section>
        </div>
      </div>
    </main>
    <Footer/>
    </>
  )
}

export default NewsArticle
