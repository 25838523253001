import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { fetchByEndpoint, handleContent } from '../functions/cms';
import Header from '../components/HeaderArticle';
import Footer from '../components/Footer';


const CMSPage = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [headerData, setHeaderData] = useState({
    title: '',
    subtitle: '',
    image: ''
  })
  // [x] Redirect if wrong page
  // [x] Grab correct name for page
  // [x] Fetch header info
  // [x] Fetch main body info

  useEffect(() => {
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [])
  

  useEffect(() => {
    fetchByEndpoint(location.pathname)
    .then(response => {
      // const image = getAbsoluteEndpoint(response.image);
      const image = response.image.substring(8)
      document.title = response.title + ' | Essex Air';
      setHeaderData({
        title: response.title,
        subtitle: response.synopsis,
        image: image
      })
      setData(handleContent(response.content));
    })
    .catch(
      err => {
        console.log(err)
        return navigate("/404");
      }
    )
  }, [location, navigate])


  // console.log(headerData);

  return (
    <>
    <main className='markdown-wrapper'>
        <Header
          title={ headerData.title }
          image={ headerData.image }/>
          <section>
            {data && data }
          </section>
      </main>
      <Footer/>
    </>
  )
}

export default CMSPage
