import React from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import s from './DirectionalButtons.module.css';

const DirectionalButtons = (props) => {
  const { home, backwards, forwards, label } = props;

  return (
    <div className={ s.directions }>

      <div className={ s.left }>  
        <Button className={ s.btn } size='large'>
          <Link to={ home }>
            <ArrowLeftOutlined/> Back to { label } 
          </Link>
        </Button>

      </div>
      
      <div>
        {(backwards !== '') && <Button className={ s.btn } size='large'>
          <Link to={ backwards }>
            <ArrowLeftOutlined/> Previous Article
          </Link>
        </Button>}

        {(forwards !== '') && <Button className={ s.btn } size='large'>
          <Link to={ forwards }>
            Next Article <ArrowRightOutlined/> 
          </Link>
        </Button>}
      </div>
    </div>
  )
}

export default DirectionalButtons;