import React from 'react';
import s from './Header.module.css';
import ImgWithFallbackInMarkdown from './ImgWithFallbackInMarkdown';

const SubHeader = (props) => {

  const { title, subtitle, children, banner=true, titleUpper=true, section=true, titleLower=false, invertText=false } = props;


  return (
    <>
      <div className={ s.title }>
        {titleUpper && <h1 className={ (invertText) ? s.darkText : s.lightText }>{ title }</h1>}
      </div>
      {section && <div className={ s.wide + ' ' + s.header  }>
       { banner && 
         <>
          <div className={ s.iconContainer }>
              <ImgWithFallbackInMarkdown
                alt={ 'Essex Air Consortium Logo' }
                src={ '/images/Shared/OtherLogos/logo192.png' }/>
            </div>
            <div className={ s.subtitle }>
              <h2>{ subtitle }</h2>
            </div>
        </>
        }
        
        {
          titleLower && 
          <section className={ s.section }>
            <h3 className='section-header'>{ title }</h3>
          </section>
        }

        {
          children && 
          <div className={ s.children }>
            { children }
          </div>
        }
      </div>}

    </>
  )
}

export default SubHeader
