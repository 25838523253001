import React from 'react';
import s from './Map.module.css';
import { lookupColor } from '../functions/helpers';
import { Circle, MiniHelp } from '../data/icons';
import { Tooltip } from 'antd';
import { MAP_SETUP as DATA } from '../data/setup';


const MapLegend = (props) => {

  const { level } = props;

  return (
    <section className={ `${s.partition} ${s.legend}` }>
      <h4 className={ s.sectionTitle }>
        <div>Pollution Level (µg m<sup>3</sup>)</div>
        <Tooltip
          placement="left"
          title={ DATA.mapInfo.pollutionLegend }>
          <div className={ s.info }>
            <MiniHelp/>
          </div>
        </Tooltip>
      </h4>
      <div className={ s.legendEntries }>
        <div className={ s.legendEntry }>
          <Circle col={lookupColor(10)}/>
          <div>
            <h5 className={ s.legendTitle }>Low</h5>
            <h5 className={ s.legendTitle }>{level[0]}</h5>

          </div>
        </div>

        <div className={ s.legendEntry }>
          <Circle col={lookupColor(31)}/>
          <div>
            <h5 className={ s.legendTitle }>Medium</h5>
            <h5 className={ s.legendTitle }>{level[1]}</h5>
          </div>
        </div>

        <div className={ s.legendEntry }>
          <Circle col={lookupColor(36)}/>
          <div>
            <h5 className={ s.legendTitle }>High</h5>
            <h5 className={ s.legendTitle }>{level[2]}</h5>
          </div>

        </div>

        <div className={ s.legendEntry }>
          <Circle col={lookupColor(40)}/>
          <div>
            <h5 className={ s.legendTitle }>Very High</h5>
            <h5 className={ s.legendTitle }>{level[3]}</h5>
          </div>

        </div>
      </div>
    </section>
  )
}

export default MapLegend
