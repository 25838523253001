import React from 'react';
import { Table as T } from 'antd';
import s from './Table.module.css';

import { DownloadOutlined } from '@ant-design/icons';

const Table = (props) => {

  const { data, onChange, rowKey } = props;

  // console.log(data);

  const format = [
    {
      title: 'Document',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      key: 'action',
      width: 120,
      render: (_, record) => (
          <a href={ _.url }><DownloadOutlined/> Download</a>
      ),
    },
  ];




  return (
    <section>
      <div className={ s.tableContainer }>
      <T
        rowKey={ rowKey }
        pagination={{ position: ['topRight', 'bottomRight'] }}
        className={ s.table }
        columns={ format }
        dataSource={ data }
        onChange={ onChange }/>
      </div>
    </section>
  )
}

export default Table
