import { useState, useEffect } from 'react';
import './Global.css';
import { Route, Routes } from 'react-router-dom';
import AdditionalResources from './pages/AdditionalResources';
import CaseStudies from './pages/CaseStudies';
import CMSPage from './pages/CMSPage';
import Educators from './pages/Educators';
import Game from './pages/Game';
import Home from './pages/Home';
import Info from './pages/Info';
import Learners from './pages/Learners';
import LocalAuthorities from './pages/LocalAuthorities';
import LocalAuthority from './pages/LocalAuthority';
import LostAndFound from './pages/LostAndFound';
import Map from './pages/Map';
import MarkdownPage from './pages/MarkdownPage';
import ModalCookies from './components/ModalCookies';
import Nav from './components/Nav';
import News from './pages/News';
import NewsArticle from './pages/NewsArticle';
import SchoolZone from './pages/SchoolZone';
import WhatCanIDo from './pages/WhatCanIDo';
import WhyIsAirQualityImportant from './pages/WhyIsAirQualityImportant';

const App = () => {

  // const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [cookiesPolicyHidden, setCookiesPolicyHidden] = useState(false);


  // Handle Cookies Policy on page-load
  useEffect(() => {
    const localStorageCookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if (localStorageCookies?.hidden) setCookiesPolicyHidden(true);
    // if (localStorageCookies?.accepted) setCookiesAccepted(false);
  }, [])


  // const handleCookiesAccepted = val => {
  //   setCookiesAccepted(val)
  // }

  const handleCookiePolicyHidden = val => {
    setCookiesPolicyHidden(val);
  }



  return (
    <>
      <Nav/>
      <Routes>
        <Route path='/' element={ <Home title="Index Page"/> }/>
        <Route path='/about-us' element={ <MarkdownPage/> }/>
        <Route path='/accessibility' element={ <MarkdownPage/> }/>
        <Route path='/case-studies' element={ <CaseStudies/> }/>
        <Route path='/case-studies/:id' element={ <CMSPage/> }/>
        <Route path='/contact-us' element={ <MarkdownPage/> }/>
        <Route path='/cookies' element={ <MarkdownPage/> }/>
        <Route path='/glossary' element={ <MarkdownPage/> }/>
        <Route path='/info' element={ <Info/> }/>
        <Route path='/info/:id' element={ <MarkdownPage/> }/>
        <Route path='/latest-news' element={ <News/> }/>
        <Route path='/latest-news/:id' element={ <NewsArticle/> }/>
        <Route path='/local-authorities' element={ <LocalAuthorities/> }/>
        <Route path='/local-authorities/:id' element={ <LocalAuthority/> }/>
        <Route path='/map' element={ <Map/> }/>
        <Route path='/privacy' element={ <MarkdownPage/> }/>
        <Route path='/school-zone' element={ <SchoolZone/> }/>
        <Route path='/school-zone/additional-resources' element={ <AdditionalResources/> }/>
        <Route path='/school-zone/educators' element={ <Educators/> }/>
        <Route 
          path='/school-zone/spot-the-source' 
          element={ <Game 
            src="/game1/story.html"
            title="Spot the Source"/>  
          }/>
        <Route 
          path='/school-zone/harmful-vs-harmless-emissions' 
          element={ <Game 
            src="/game2/story.html"
            title="Harmful vs Harmless Emissions"/> 
          }/>

        <Route path='/school-zone/learners' element={ <Learners/> }/>
        <Route path='/school-zone/why-is-air-quality-important' element={ <WhyIsAirQualityImportant/> }/>

        <Route path='/what-can-i-do' element={ <WhatCanIDo/> }/>

        <Route path='/404' element={ <LostAndFound/> }/>
        <Route path='*' element={ <LostAndFound/> }/>
      </Routes>
      {!cookiesPolicyHidden && <ModalCookies 
        handleCookiePolicyHidden={ handleCookiePolicyHidden }/>}
      </>
  )
}

export default App;



// <Route path='/monitoring-sites' element={ <MonitoringSites/> }/>
// <Route path='/monitoring-sites/:id' element={ <Monitor/> }/>
