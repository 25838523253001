import React, { useState, useEffect, useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import s from './Arrows.module.css';

function Arrow({
  children,
  disabled,
  onClick
}) {
  return (
    <button
      disabled={ disabled }
      onClick={ onClick }
      className={ (disabled) ? s.invisible :  s.arrow }>
      {children}
    </button>
  );
}

export function LeftArrow() {

  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <CaretLeftOutlined/>
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext(
    VisibilityContext
  );

  const [disabled, setDisabled] = useState(
    !visibleElements.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <CaretRightOutlined />
    </Arrow>
  );
}
