import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import s from './ListTall.module.css';
import { ImgWithFallback, generateImageProps } from '../functions/cms';
import { ArrowRightOutlined } from '@ant-design/icons';


const ListTallItem = (props) => {

  const { title, subtitle, subtitleOther=false, to, image=null, size='large', button=false, buttonText='Learn More', children, isInteractive=true, displayTitle=true, external=false } = props;

  const imageProps = generateImageProps(image);

  return (
    <>
     { isInteractive && <>
     { (!external) && <Link className={ s.container } to={ to }>
        <div className={ (size==="large") ? `${s.imageContainer} ${s.imageContainerLarge}` : s.imageContainer }>
          {
            image &&
            <ImgWithFallback
              className={ (size==="large") ? s.imageLarge : s.image }
              fileName={ imageProps.fileName }
              folder={ imageProps.folder }
              alt={ title }
              title={ title }/>
          }
        </div>

        <div className={ s.right }>
          <div className={ (size==="large") ? `${s.textLargeImage} ${s.text}` : s.text }>
            { displayTitle && <h3 className={ s.title }>{ title }</h3> }
            <div className={ s.subTitle }>
              { !subtitleOther && <p className={ s.para }>{ subtitle }</p> }
              { subtitleOther && <div className={ s.para }>{ subtitle }</div> }

              { children }
            </div>
          </div>
          { 
            button && 
            <div>
              <Button className={ s.button }>{ buttonText } <ArrowRightOutlined/></Button>
            </div> 
          }
        </div>
      </Link>}

      { (external) && <a className={ s.container } href={ to }>
        <div className={ (size==="large") ? `${s.imageContainer} ${s.imageContainerLarge}` : s.imageContainer }>
          {
            image &&
            <ImgWithFallback
              className={ (size==="large") ? s.imageLarge : s.image }
              fileName={ imageProps.fileName }
              folder={ imageProps.folder }
              alt={ title }
              title={ title }/>
          }
        </div>

        <div className={ s.right }>
          <div className={ (size==="large") ? `${s.textLargeImage} ${s.text}` : s.text }>
            { displayTitle && <h3 className={ s.title }>{ title }</h3> }
            <div className={ s.subTitle }>
              { !subtitleOther && <p className={ s.para }>{ subtitle }</p> }
              { subtitleOther && <div className={ s.para }>{ subtitle }</div> }

              { children }
            </div>
          </div>
          { 
            button && 
            <div>
              <Button className={ s.button }>{ buttonText } <ArrowRightOutlined/></Button>
            </div> 
          }
        </div>
      </a>}

      </>
    }

    { !isInteractive &&
      <div className={ s.containerNonInteractive }>
        <div className={ (size==="large") ? `${s.imageContainerNonInteractive} ${s.imageContainerLarge}` : s.imageContainer }>
          {
            image &&
            <ImgWithFallback
              className={ (size==="large") ? s.imageLarge : s.image }
              fileName={ imageProps.fileName }
              folder={ imageProps.folder }
              alt={ title }
              title={ title }/>
          }
        </div>

        <div className={ s.right }>
          <div className={ (size==="large") ? `${s.textLargeImage} ${s.text}` : s.text }>
          { displayTitle && <h3 className={ s.title }>{ title }</h3> }
            <div className={ s.subTitle }>
              <p className={ s.para }>{ subtitle }</p>
              { children }
            </div>
          </div>
          { 
            button && 
            <div>
              <Button className={ s.button }>{ buttonText } <ArrowRightOutlined/></Button>
            </div> 
          }
        </div>
      </div>
    }
    </>
  )
}

export default ListTallItem

// { image &&
// <img src={ image } className={ s.image } alt={ title } title={ title }/>
// }