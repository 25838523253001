import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Tabs } from 'antd';
import ReactGA from 'react-ga4';

import Footer from '../components/Footer';
import ImageBanner from '../components/ImageBanner';
import MarkdownContent from '../components/MarkdownContent';

import content from '../data/pageContent.json';
import s from './SchoolZone.module.css';

const WhatCanIDo = (props) => {


  const [tabSelection, setTabSelection] = useState({
    main: 'emissions',
    exposure: 'out',
    emissions: 'out'
  });
  

  const page = content['what-can-i-do'];

  useEffect(() => {
    document.title = page['page-title'];
    window.scrollTo(0, 0);
    const cookies = JSON.parse(localStorage.getItem("cookiePreferences"));
    if ( cookies?.accepted ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [page]);


  const handleMainTab = (e) => {
    setTabSelection({...tabSelection, main: e});
  }

  const handleExposureTab = (e) => {
    setTabSelection({...tabSelection, exposure: e});
  }

  const handleEmissionsTab = (e) => {
    setTabSelection({...tabSelection, emissions: e});
  }

  const exposureHome = tabSelection.main === 'exposure' && tabSelection.exposure === 'home';
  const exposureOut = tabSelection.main === 'exposure' && tabSelection.exposure === 'out';
  const emissionsHome = tabSelection.main === 'emissions' && tabSelection.emissions === 'home';
  const emissionsOut = tabSelection.main === 'emissions' && tabSelection.emissions === 'out';



  return (
    <main>
      <ImageBanner
        wide={ true }
        title={ page.title }
        subtitle={ page.subtitle }
        image={ page.image }/>

      <Header
        image={ page.image }
        wide={ true }
        title={ page.title }
        subtitle={ page.subtitle }>

        <Tabs
          centered
          size={'large'}
          className={ s.tabs }
          defaultActiveKey="emissions"
          onChange={ handleMainTab }
          items={[
            {
              label: `Reducing emissions`,
              key: 'emissions',
              children: <Tabs
              centered
              size={'large'}
              className={ s.tabs }
              defaultActiveKey="out"
              onChange={ handleEmissionsTab }
              items={[
                {
                  label: `Out and about`,
                  key: 'out',
                  children: <></>,
                },
                {
                  label: `In your home`,
                  key: 'home',
                  children: <></>,
                }
              ]}
            />,
            },
            {
              label: `Reducing exposure`,
              key: 'exposure',
              children: <Tabs
                centered
                size={'large'}
                className={ s.tabs }
                defaultActiveKey="out"
                onChange={ handleExposureTab }
                items={[
                  {
                    label: `Out and about`,
                    key: 'out',
                    children: <></>,
                  },
                  {
                    label: `In your home`,
                    key: 'home',
                    children: <></>,
                  }
                ]}
              />,
            }
            
          ]}
        />


      </Header>

    <div className={ 'body' }>
      <div className={ 'body-container' }>
        <div className='markdown-wrapper'>

          { 
            exposureHome && 
            <section>
              <h3 className={ 'section-header-short' }>Reducing exposure - in your home</h3>
              <MarkdownContent pathname='/whatCanIDo/reducing-exposure-in-your-home'/>
            </section>
          }

          { 
            exposureOut && 
            <section>
              <h3 className={ 'section-header-short' }>Reducing exposure - out and about</h3>
              <MarkdownContent pathname='/whatCanIDo/reducing-exposure-out-and-about'/>
            </section>
          }

          { 
            emissionsHome && 
            <section>
              <h3 className={ 'section-header-short' }>Reducing emissions - in your home</h3>
              <MarkdownContent pathname='/whatCanIDo/reducing-emissions-in-your-home'/>
            </section>
          }

          { 
            emissionsOut && 
            <section>
              <h3 className={ 'section-header-short' }>Reducing emissions - out and about</h3>
              <MarkdownContent pathname='/whatCanIDo/reducing-emissions-out-and-about'/>
            </section>
          }

        </div>
      </div>
    </div>
    <Footer/>
  </main>
  )
}

export default WhatCanIDo
