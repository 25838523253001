import React from 'react'
import { CrossCircle } from '../data/icons';
import { Button } from 'antd';

import { MAP_SETUP as DATA } from '../data/setup';

import s from './Modal.module.css';

const Modal = (props) => {

  const { handleModalActive } = props;



  const content = DATA.mapModal


  const stopModalPropagation = (e) => e.stopPropagation();

  return (
    <div className={ s.modalBackground } onClick={ handleModalActive }>
      <div className={ s.modal } onClick={e => stopModalPropagation(e)}>
        <div className={ s.modalHeader }>
          <h2 className={ s.header }>{ content.title }</h2>
          <button className={ s.icon } onClick={ handleModalActive }>
            <CrossCircle col={'var(--grey0)'}/>
          </button>
        </div>
        <div className={ s.body }>
          <p className={ s.p }>{ content.a }</p>
          <p className={ s.p }>{ content.b }</p>
          <p className={ s.p }>{ content.c }</p>
          <p className={ s.p }>{ content.d }</p>
          <Button
            onClick={ handleModalActive } 
            type='primary'>Go to Map</Button>
        </div>
      </div>
    </div>
  )
}

export default Modal
