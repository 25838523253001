import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { MAP_SETUP as DATA } from '../data/setup';

import s from './MapSection.module.css';

import AQMA_BOUNDARIES from '../data/json/AQMA.json';
// import LA_BOUNDARIES from '../data/json/LOCAL_AUTHORITIES.json';



const MapSection = (props) => {

  const { aqmaName, bounds, loc } = props;

  const [aqmaBoundaries, setAqmaBoundaries] = useState(AQMA_BOUNDARIES);

  // const [laBoundaries, setLaBoundaries] = useState(null);

  // const [map, setMap] = useState(null);

  useEffect(() => {
    setAqmaBoundaries(AQMA_BOUNDARIES)
    // setLaBoundaries(LA_BOUNDARIES)
  }, [])

  const boundaries = aqmaBoundaries.features.reduce((acc, a) => {
    if (a.properties.local_auth === aqmaName) {
      acc.features.push(a)
    }
    return acc;
  }, DATA.geojsonStructure)


  // const laBoundary = laBoundaries.features.reduce((acc, a) => {
  //   if (a.properties.NAME === laName) {
  //     acc.features.push(a)
  //   }
  //   return acc;
  // }, DATA.geojsonStructure)



  const handleMap = useCallback((node) => {
    if (node !== null) {
      node.fitBounds(bounds, {padding: [20, 20]})
      // setMap(node);

    }
  }, [bounds])

  return (
    <section>

      <MapContainer
        zoomControl={ false }
        zoomSnap={ false }
        dragging={ false }
        scrollWheelZoom={ false }
        className={ s.map }
        ref={ handleMap }>

        <Link
          to={ `/map?bounds=${loc}` }
          className={ s.clickLayer }/>
        <div className={ s.mapLabel }>AQMAs - Click map to view in more detail</div>

        <TileLayer
          attribution={ DATA.basemapAttribution }
          url={ DATA.basemapTileLayer }/>

        <GeoJSON
          data={ boundaries }
          style={ DATA.styleAqmaBoundaries }
          name={ 'aqmaBoundaries' }/>



      </MapContainer>
    </section>
  )
}

export default MapSection
