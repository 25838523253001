import React from 'react';
import s from './HeaderArticle.module.css';
import { ImgWithFallback, generateImageProps } from '../functions/cms';


const Header = (props) => {

  const { title, subtitle, wide, image, alt } = props;

  const imageProps = generateImageProps(image);
  return (
    <header className={ (wide) ? s.header + ' ' + s.wide : s.header  }>

      {
        image &&
        <>
          <div className={ s.imageContainer }>
            <ImgWithFallback
              className={ s.image }
              fileName={ imageProps.fileName }
              folder={ imageProps.folder }
              alt={ alt }
              title={ title }/>
          </div>
        </>
      }

      {/* {
        !image &&
        <>
          <div className={ s.imageContainer }>
            <ImgWithFallback
              className={ s.image }
              fileName={ "huts.jpg" }
              folder={ "/images/Shared/General/" }
              alt={ alt }
              title={ title }/>
          </div>
        </>
      } */}

      <section className={ s.headerSection }>
        <h1 className={`section-header ${s.h1}`}>{ title }</h1>
      </section>
 
      {subtitle && <div className={ s.subtitle }>
        <h2 className={ s.h2 }>{ subtitle }</h2>
      </div>}

    </header>
  )
}

export default Header
