import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import s from './ListDualTallItem.module.css';
import { ImgWithFallback, generateImageProps } from '../functions/cms';
import { ArrowRightOutlined } from '@ant-design/icons';


const ListDualTallItem = (props) => {

  const { title, to, image=null, subtitle, buttonText="Read More", external=false } = props;

  const imageProps = generateImageProps(image);

  
  return (
    <>
    {(!external) && <Link className={ s.container } to={ to }>

      <div className={ s.imageContainer }>
        {
          image && <ImgWithFallback 
            className={ s.image }
            fileName={ imageProps.fileName }
            folder={ imageProps.folder }
            alt={ title }
            title={ title } />
        }
      </div>

      <div className={ s.right }>
        <div className={  s.text }>
          <h4 className={ s.title }>{ title }</h4>
          {subtitle && <p>{ subtitle }</p>}
        </div>
        <div className={ s.buttonWrapper }>
          <Button className={ s.button }>{ buttonText } <ArrowRightOutlined/></Button>
        </div> 
      </div>

    </Link>
    }

    {
      (external) && <a className={ s.container } href={ to }>

      <div className={ s.imageContainer }>
        {
          image && <ImgWithFallback 
            className={ s.image }
            fileName={ imageProps.fileName }
            folder={ imageProps.folder }
            alt={ title }
            title={ title } />
        }
      </div>

      <div className={ s.right }>
        <div className={  s.text }>
          <h4 className={ s.title }>{ title }</h4>
          {subtitle && <p>{ subtitle }</p>}
        </div>
        <div className={ s.buttonWrapper }>
          <Button className={ s.button }>{ buttonText } <ArrowRightOutlined/></Button>
        </div> 
      </div>

    </a>
    }

    </>
  )
}

export default ListDualTallItem

// { image &&
// <img src={ image } className={ s.image } alt={ title } title={ title }/>
// }