import s from './DualImage.module.css';
import ImgWithFallbackInMarkdown from './ImgWithFallbackInMarkdown';


const DualImage = (props) => {

  const { src1, src2, alt1, alt2 } = props;

  return (
    <div className={ s.wrapper }>
      <ImgWithFallbackInMarkdown src={ src1 } alt={ alt1 } />
      <ImgWithFallbackInMarkdown src={ src2 } alt={ alt2 } />
    </div>
  )
};

export default DualImage;