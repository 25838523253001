import React from 'react';
import s from './Map.module.css';
import { Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';



const FilterButton = (props) => {
  const { onClick } = props;

  return (
    <div className={ s.filterButton }>
    <Button
      icon={ <FilterOutlined /> }
      onClick={ () => onClick(true) }
      shape="circle"
      size="large"/>
    </div>
  )
}

export default FilterButton
