import { lookupColor } from '../functions/helpers';


export const TABLE_SETUP = {
  filters: {
    pollutants: [
      { text: 'NO2', value: 'NO2' },
      { text: 'PM10', value: 'PM10' },
      { text: 'PM2.5', value: 'PM2.5' },
    ],
    monitorTypes: [
      { text: 'Façade', value: 'Façade' },
      { text: 'Industrial', value: 'Industrial' },
      { text: 'Kerbside', value: 'Kerbside' },
      { text: 'Roadside', value: 'Roadside' },
      { text: 'Rural', value: 'Rural' },
      { text: 'Rural background', value: 'Rural background' },
      { text: 'Suburban', value: 'Suburban' },
      { text: 'Urban Background', value: 'Urban Background' },
      { text: 'Urban Centre', value: 'Urban Centre' },
      { text: 'Other', value: 'Other' },
    ],
    localAuthorities: [
      { text: 'Basildon', value: 'Basildon' },
      { text: 'Braintree', value: 'Braintree' },
      { text: 'Brentwood', value: 'Brentwood' },
      { text: 'Castle Point', value: 'Castle Point' },
      { text: 'Chelmsford', value: 'Chelmsford' },
      { text: 'Colchester', value: 'Colchester' },
      { text: 'Epping Forest', value: 'Epping Forest' },
      { text: 'Harlow', value: 'Harlow' },
      { text: 'Maldon', value: 'Maldon' },
      { text: 'Rochford', value: 'Rochford' },
      { text: 'Southend', value: 'Southend' },
      { text: 'Tendring', value: 'Tendring' },
      { text: 'Thurrock', value: 'Thurrock' },
      { text: 'Uttlesford', value: 'Uttlesford' }
    ]
  }
}

export const MAP_SETUP = {

  activePointLayer: {
    timePeriod: 'Historical',
    year: 2022,
    pollutantType: 'NO2',
    minMax: [0, 40],
    maxNO2: 40,
  },

  activeLayers: {
    diffusionTubes: true,
    localAuthorityBoundaries: true,
    laLabels: true,
    apiData: false,
    aqmaBoundaries: true,
  },

  stylePoints: (val) => ({
    radius: 7,
    opacity: 1,
    fillColor: lookupColor(val),
    color: "#a7a7a7",
    fillOpacity: 1,
    tabIndex: 10,
    bubblingMouseEvents: false,
    weight: 0.5,
  }),

  styleAqmaBoundaries: {
    color: "var(--main2)",
    strokeWidth: 2,
    strokeOpacity: 1,
    fillColor: "var(--main4)",
    fillOpacity: 0.2,
  },

  styleLaBoundaries: {
    color: "#555555",
    weight: 1.5,
    strokeOpacity: 1,
  },

  styleLaBoundariesFill: {
    color: "var(--main2)",
    strokeWidth: 1,
    strokeOpacity: 1,
    fillColor: "var(--main3)",
    fillOpacity: 0.5
  },



  minMaxPM25: [0, 20],
  maxPM25: 20,

  sliderMarks: {
    0: 'None',
    30: 'Low',
    36: 'Med',
    40: 'High'
  },

  sliderMarksPM25: {
    0: 'None',
    15: 'Low',
    18: ' ',
    20: 'High'
  },

  levels: ['< 30', '30 < 36', '36 < 40', '> 40'],

  levelsPM25: ['< 15', '15 < 18', '18 < 20', '> 20'],

  geojsonStructure: {
    "type": "FeatureCollection",
    "name": "diffusion",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": []
  },

  lookup: {
    DES: 'Site Name',
    ID: 'Site ID',
    M: 'Monitor Type',
    CMS: 'Continuous Monitoring Station',
    POL: 'Pollutant',
    YR: 'Year',
    VAL: 'Annual Mean (µg/m3)',
    TYP: 'Position',
    DT: 'Diffusion Tube',
    Annual_Mea: 'Exceedance of Annual Mean Air Quality Objective',
    Hourly_Mea: 'Exceedance of Hourly Mean Air Quality Objective',
    NOT_: 'Notes',

    siteID: 'Site ID',
    siteName: 'Site Name',
    siteType: 'Site Type',
    source: 'API Source',
    no2HourlyAvg: 'NO2 Average',
    pm25DailyAvg: 'PM2.5 Daily Average', 
    pm10DailyAvg: 'PM10 Daily Average',

    descriptio: 'Location Description',
    correct_as_of: 'Last Updated',
    Basildon: "https://www.basildon.gov.uk/article/712/Pollution-control-Air-Quality",
    Braintree: "https://www.braintree.gov.uk/advice-environment/air-quality-pollution/2",
    Brentwood: "https://www.brentwood.gov.uk/air-quality",
    "Castle Point": "https://www.castlepoint.gov.uk/air-quality-in-castle-point/",
    Chelmsford: "https://www.chelmsford.gov.uk/environment-and-public-health/air-quality/",
    Colchester: "https://www.colchester.gov.uk/environmental-protection/air-quality-in-colchester/",
    "Epping Forest": "https://www.eppingforestdc.gov.uk/environment/local-air-quality-management/",
    Harlow: "https://www.harlow.gov.uk/environment-and-animals/pollution/air-quality",
    Maldon: "https://www.maldon.gov.uk/info/20099/pollution/9148/air_quality",
    Rochford: "https://www.rochford.gov.uk/environment/air-quality/air-quality-management-area-aqma",
    Southend: "https://www.southend.gov.uk/protecting-environment-emergencies/air-quality",
    Tendring: "https://www.tendringdc.gov.uk/environment/pollution-noise/air-quality",
    Thurrock: "https://www.thurrock.gov.uk/air-quality/air-quality-monitoring",
    Uttlesford: "https://www.uttlesford.gov.uk/article/5719/Air-quality"
  },


  lookupToRemove: ['PLOT', 'X', 'Y', 'VAL', 'POL', 'YR', 'M', 'LA', 'local_auth', 'Link', 'title', 'longitude', 'latitude', 'localAuthority', 'siteID', 'measurementDateTime', 'no2Latest', 'pm10Latest', 'pm25Latest', 'pm25DailyAvg', 'pm10DailyAvg', 'no2HourlyAvg', 'site_id', 'latestMeasurementDateTime', 'no2', 'so2', 'pm10', 'benzene', 'fid_', 'aqma_ref', 'la_id', 'no2_lv', 'pm10_lv', 'benzene_lv', 'aqma_id', 'la_ons_id', 'la_ons_nam', 'envelope'],

  initialBounds: [
    [52.116379017570296, -0.08826466583179535],
    [51.39717994862758, 1.3244707012396801],
  ],

  initialBoundsExtra: [
    [52.116379017570296, -0.08826466583179535],
    [51.34717994862758, 1.3244707012396801],
  ],



  yearOptions: [
    {value: 2022, name: 2022 }, 
    {value: 2021, name: 2021 }, 
    {value: 2020, name: 2020 },
    {value: 2019, name: 2019 },
    {value: 2018, name: 2018 },
    {value: 2017, name: 2017 },
    {value: 2016, name: 2016 },
    {value: 2015, name: 2015 }

  ],
  pointOptions: [
    {value: 'Historical', name: 'Historical' }, 
    {value: 'Live', name: 'Live' }
  ],
  pollutantOptions: [
    {value: 'NO2', name: 'NO2 (Nitrogen Dioxide)' },
    {value: 'PM10', name: 'PM10 (Particulate Matter)' },
    {value: 'PM2.5', name: 'PM2.5 (Particulate Matter)' }
  ],

  mapTitle: "Essex Air Quality Map",

  mapBio: "Use this map to look at live air quality monitoring data or the results of air quality monitoring undertaken within each of the local authorities in Essex in previous years.",

  mapModal: {
    title: 'Essex Air Quality Map',
    a: "This map summarises the results of air quality monitoring undertaken by each of the local authorities within Essex. Both 'historical' and 'live' data are presented.",
    b: "The historical data (i.e. data for previous years) has been checked and can be considered final, however, the live data presented is provisional and subject to change.",
    c: "The historical data presented has been extracted from each of the relevant local authority reports which can be found on this website. Whilst every effort has been made to collate and present this data accurately within this map, no reliance should be placed on the data presented herein.",
    d: "Instead, the relevant local authority report should be consulted. It should also be noted that the locations of the monitoring sites presented herein are approximate and have not been verified. Where necessary therefore, the exact locations of each of these monitoring sites should be confirmed with the relevant local authority.",
  },

  mapInfo: {
    layers: 'Use the selectors to choose which layers you would like shown on the map.',
    pollutantType: 'Choose between nitrogen dioxide (NO2) or particulate matter (PM10 or PM2.5).',
    pollutionLegend: 'Concentration pollutant bands relevant for each air quality objective.',
    pollutionSlider: 'Use the slider to limit the monitoring sites shown on the map according to their pollution level category.',
    timePeriod: 'Choose "Historical" to view the results of air quality monitoring undertaken in previous years or "Live" if you wish to view real time air quality monitoring data.',
    year: 'Select the year for which you wish to view historical data.',
  },

  basemapAttribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
  // basemapTileLayer: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
  basemapTileLayer: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',

}

export const FIELD_SETUP = {
  checks: [
    {type: 'diff', val: 'LA'},
    {type: 'aqma', val: 'local_auth'},
    {type: 'api', val: 'site_id'},
  ],
  title: {
    diff: 'DES',
    aqma: 'title',
    api: 'siteName',  
  },
  api: {
    NO2: 'no2Latest',
    PM10: 'pm10Latest',
    "PM2.5": 'pm25Latest',
    NO2Hourly: 'no2HourlyAvg',
    PM10Daily: 'pm10DailyAvg',
    "PM2.5Daily": 'pm25DailyAvg',
  },

}

export const LOCAL_AUTHORITY_TABS = [
  {
    name: 'About',
    textContent: (<></>),
  },
  {
    name: 'Reports',
    textContent: (<></>),
  },
  {
    name: "AQMA's",
    textContent: (<></>),
  },
  {
    name: 'Contact Us',
    textContent: (<></>),
  },
]

export const API_SETUP = {
  geojson: {
    type: "FeatureCollection",
    features: []
  },
  fields: {
    lon: 'longitude',
    lat: 'latitude'
  }
}